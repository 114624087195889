.single-pdf {
    // + + + + + + + + + + + +
    // overriding

    background-color: white;
    color: $blue;
    font-size: 1.4rem;
    letter-spacing: -0.025em;
    line-height: 1.2; // 1.7rem

    strong {
        font-weight: 600;
    }

    section {
        padding: 3rem 2rem 0 2rem;
    }

    h1 {
        font-size: 7.5rem;
        font-weight: $ultralight;
        margin-bottom: 0;
        line-height: 0.9;
    }

    h2 {
        font-size: 3rem;
        letter-spacing: -0.025em;

        &.titol-highlight {
            width: 100%;
            position: relative;
            left: -0.07em;
            font-size: 3rem;
            letter-spacing: -0.025em;
            line-height: calc(85 / 75);
            color: #EBB32C;
        }
    }

    .titol {
        font-size: 1.6rem;
        color: $orange;
        font-weight: $semibold;
        // margin-bottom: 2.5rem;
        margin-bottom: 1.7rem;
    }

    p {
        font-size: 1.4rem;
        letter-spacing: -0.025em;
        line-height: 1.2; // 1.7rem
        margin: 0;
        padding-top: 1rem;
    }

    img.enchufe {
        width: 6.7rem;
        margin: 0.5rem !important;
        display: inline-block !important;
    }

    // + + + + + + + + + + + +
    // custom

    .format-text {
        h1 {
            font-size: 1.6rem;
            color: $orange;
            font-weight: $semibold;
            margin-bottom: 0;
            padding-top: 3.2rem;
            margin-top: 0;
        }
        h1 + * {
            padding-top: 1.7rem !important;
        }
        h2 {
            font-size: 1.6rem;
            line-height: 1.9rem;
            letter-spacing: -0.025em;
            margin-bottom: 1.2em;
            font-weight: 600;
            margin-bottom: 0;
            font-family: $font;
        }
        h2 + * {
            padding-top: 0 !important;
        }
        table {
            margin-bottom: 1.5rem;
        }
    }

    table,
    .format-text table,
    .format-text table p {
        font-size: 1.1rem !important;
        line-height: 1.3rem;
        letter-spacing: 0;
        width: 100%;
        margin-bottom: 1rem;

        strong {
            font-weight: 400 !important;
        }

        tr {
            border-bottom: 1px solid $blue;
            td {
                padding-bottom: 0.7rem;
                padding-top: 0.7rem;
                vertical-align: top;
            }
        }

        p {
            margin-bottom: 0 !important;
        }

        &:not(.no-header) {
            tr:first-of-type,
            tr:first-of-type strong {
                color: $orange;
                font-weight: 600 !important;
                border-width: 0 !important;

                td {
                    padding-top: 2rem;
                }
            }
        }
    }

    table.no-header {
        .preus-header {
            border-width: 0;
            th{
                padding-bottom: 0.1em;
            }
        }
    }

    .restyle .fitxa-equipo-recomendado {
        #equip-wrapper {
            margin: 0.6rem 0 2.3rem 0;
        }

        #equip-wrapper-inner {
            border-radius: 0.8rem;
            border: 0.5px solid $orange;
            padding: 0.7rem 1.7rem;
        }

        table:not(.no-header) {
            margin-bottom: 0;

            tr:first-of-type {
                border-radius: 0.8rem;
                border: 0.5px solid $orange;
                p {
                    font-size: 1.3rem !important;
                    line-height: 1.56rem !important;
                    letter-spacing: -0.025em;
                    font-weight: 400 !important;
                    strong {
                        font-weight: 600 !important;
                    }
                }

                td:first-of-type {
                    width: 27rem;
                    padding-bottom: 1.2rem;
                    padding-top: 1.2rem;
                }

                td:nth-of-type(2) {
                    padding: 0;
                    img {
                        display: block;
                        margin: -0.6rem auto !important;
                        padding: 0 1rem 0 3rem;
                        width: 16.5rem;
                        height: auto;
                    }
                }
            }
        }
    }

    .text-destacat,
    .text-destacat p {
        font-size: 1.6rem;
        line-height: 1.9rem;
        letter-spacing: -0.025em;
        margin-bottom: 1.2em;
        color: $hightlightgray;
    }

    .text-normal,
    .text-normal p {
        font-size: 1.4rem;
        line-height: 1.7rem;
        letter-spacing: -0.025em;
        margin-bottom: 1.2em;
    }

    .llegenda-wrapper {
        padding: 3rem 0;
    }

    .llegenda {
        border-radius: 2.6rem;
        line-height: 1;
        font-size: 1.1rem;
        padding: 0.6rem 1.9rem;
        border: 1px solid $orange;
        color: $orange;
        width: fit-content;
        margin: 0 auto;
    }

    // sections

    .header {
        display: none;
        position: fixed;
        top: 0;

        background-color: $lightgrey;

        &__logo {
            height: 9rem;
            background-position: left center;
        }
    }

    .footer {
        display: none;
        font-size: 1rem;

        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .s0 {
        padding-top: 0;

        &__topwrap {
            margin-bottom: 3.8rem;

            .logo {
                width: 14rem;
                margin-bottom: 4.5rem;
                margin-top: 1rem;
            }
            .titol {
                margin-bottom: 1.35rem;
            }

            .titol-gran {
                width: 100%;
                position: relative;
                left: -0.07em;
                margin-bottom: -0.2rem; // compensate spacing rules
                margin-top: 0;
                line-height: 0.75;
            }

            .titol-highlight {
                margin-top: 0.8rem;
            }
        }

        &__bottomwrap {
            max-width: 100%;

            .titol {
                margin-top: 0;
            }
        }

        &__map {
            max-height: 60rem; // be in the first page
            max-width: 100%;
            margin: 2.5rem auto 5rem auto;
            display: block;
        }
    }

    .s1 {
        &__sortides {
            ul {
                // margin: 2.5rem 0;

                // column-count: 4;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                gap: 0 5%;
                max-height: 4rem;

                font-size: 1.1rem;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 1.3rem;

                // li {
                //     max-width: 20%;
                // }
            }
        }
    }

    .s2 {
        article:nth-last-child(2) {
            margin-bottom: 0;
        }

        .titol {
            margin-bottom: 0;
        }

        .titol + article {
            padding-top: 1.6rem;
        }

        &__detalle {
            // margin-bottom: 2.2rem;
            padding-top: 2.2rem;

            .heading {
                // padding-bottom: 1rem;
                font-weight: 600;
            }

            .text-detalle {
                font-size: 1.4rem;
                line-height: 1.7rem;

                p {
                    font-size: 1.4rem;
                    line-height: 1.7rem;
                    padding-top: 1rem;
                    margin-bottom: 0;
                    margin-top: 0;

                    &:first-of-type {
                        padding-top: 0;
                    }
                }

                blockquote {
                    margin-left: 7.7rem;
                    padding-top: 1.5rem;
                    padding-bottom: 0.5rem;
                    font-size: 1.2rem;

                    &::before {
                        display: block;
                        content: 'Nota';
                        font-weight: 600;
                        color: $orange;
                    }
                }

                .MsoTableGrid {
                    td {
                        border-width: 0 !important;

                        padding-top: 1rem;
                        padding-left: 1rem;
                        padding-bottom: 1rem;
                    }
                    td {
                        font-weight: 400 !important;
                        font-style: normal !important;
                        color: $blue !important;

                        strong {
                            font-weight: 600 !important;
                            color: $orange !important;
                        }

                        em {
                            font-style: normal !important;
                        }
                    }
                }
            }

            img {
                padding-top: 1rem;
            }
        }
    }

    .s3 {
        // ajuste enchufes antiguos
        p > img {
            display: inline-block;
            padding: 0;
        }
    }

    // + + + + + + + + + + + +
    // fitxes restyle

    .restyle {
        img {
            // objetivos de desarrollo sostenible
            margin: 0 auto;
            padding: 1rem 0;
            display: block;
        }

        .fitxa-formulario {
            // padding-top: 1rem;

            // > :first-child {
            //     margin-top: 5rem;
            // }

            table {
                width: 100%;
                margin-bottom: 2rem;

                tr:first-of-type,
                tr:first-of-type strong {
                    color: $blue;
                    font-weight: 400 !important;
                }

                tr {
                    border-width: 0;
                    td {
                        line-height: 1.7;
                        padding: 0.4rem;

                        p {
                            margin: 0;
                            border-bottom: 0.5px solid $blue;
                            font-size: 1rem !important;
                            letter-spacing: 0;
                            line-height: 1.6;
                        }

                        &:first-of-type {
                            padding-left: 0;
                        }

                        &:last-of-type {
                            padding-right: 0;
                        }
                    }
                }

                // tr:nth-child(2n) {
                //     height: 10!important;
                //     display: block;
                // }
            }

            table + p {
                font-size: 0.65em;
                margin-bottom: 3em;
                margin-top: -2rem;
                text-align: right;
                max-width: 48rem;
                margin-left: auto;
            }
        }
    }

    // + + + + + + + + + + + +
    // listas

    .s2 .format-text.restyle {
        ul {
            li {
                font-size: 1.1rem;
                line-height: 1.6rem;
            }
        }
    }

    // + + + + + + + + + + + +
    // spacing pdf etc

    // .restyle .fitxa-formulario,
    // .restyle .fitxa-formulario + *,
    // .break {
    //     page-break-before: always;
    // }

    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;

    .titol-highlight {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    table,
    .format-text table,
    .format-text table p {
        page-break-before: auto;
    }

    section {
        page-break-after: always;
    }

    .fitxa-formulario {
        page-break-before: always;
    }

    .hr-wrapper,
    hr {
        page-break-before: avoid;
        page-break-inside: avoid;
    }

    img,
    table,
    h1,
    h2,
    p,
    ul,
    blockquote {
        page-break-inside: avoid;
    }

    h1,
    h2,
    .titol,
    .s2 .heading {
        page-break-after: avoid; // not supported

        & + p {
            padding-top: 0;
        }
    }

    table p {
        padding-top: 0;
    }

    // pdf hider
    #hide-pdf {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        background-color: $cream;
        color: $orange;
        font-size: 2.5rem;
        font-weight: 500;

        // from https://loading.io/css/

        .lds-ellipsis {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
        }
        .lds-ellipsis div {
            position: absolute;
            top: 33px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: $orange;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
        }
        .lds-ellipsis div:nth-child(1) {
            left: 8px;
            animation: lds-ellipsis1 1.6s infinite;
        }
        .lds-ellipsis div:nth-child(2) {
            left: 8px;
            animation: lds-ellipsis2 1.6s infinite;
        }
        .lds-ellipsis div:nth-child(3) {
            left: 32px;
            animation: lds-ellipsis2 1.6s infinite;
        }
        .lds-ellipsis div:nth-child(4) {
            left: 56px;
            animation: lds-ellipsis3 1.6s infinite;
        }
        @keyframes lds-ellipsis1 {
            0% {
                transform: scale(0);
            }
            100% {
                transform: scale(1);
            }
        }
        @keyframes lds-ellipsis3 {
            0% {
                transform: scale(1);
            }
            100% {
                transform: scale(0);
            }
        }
        @keyframes lds-ellipsis2 {
            0% {
                transform: translate(0, 0);
            }
            100% {
                transform: translate(24px, 0);
            }
        }
    }
}
