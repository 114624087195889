.form {
    font-size: $f-45;
    letter-spacing: -0.029em;
    line-height: 6.3rem;
    font-weight: 500;

    .btn {
        margin-top: 1rem;
    }

    &__select,
    &__input {
        position: relative;
        margin-bottom: 0.62em;
        cursor: pointer;
        &--orange {
            &::before {
                background-image: url('../img/arrow-right-orange.svg') !important;
            }
        }
    }

    &__select::before,
    &__input::before {
        display: block;
        position: absolute;
        content: '';
        background-image: url('../img/arrow-right-blue.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
        width: 4rem;
        left: -5.3rem;
        bottom: 0.08em;

        // animation
        transition: all 0.5s ease;
        &--orange {
            background-image: url('../img/arrow-right-orange.svg');
        }
    }

    // animation
    &__select:hover::before,
    &__input:hover::before {
        transform: translateX(1rem);
    }

    &__select {
        position: relative;
        width: fit-content;

        &__field {
            padding-right: 4rem;
            cursor: pointer;
        }

        // &__field:focus {

        //     border: 0;
        //     box-shadow: none;
        //     padding: 0;
        //     background-color: $grey;

        //     option{
        //         background-color: $grey;
        //         color: $orange;
        //         font-family: $font;
        //         font-size: 0.8em;
        //     }
        // }

        &__arrow {
            pointer-events: none;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);

            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            background-image: url('../img/form-arrow.svg');
            width: 2rem;
            height: 1.1rem;
        }

        .select2-container--below:not(.select2-container--open) + &__arrow {
            background-image: url('../img/form-arrow-blue.svg') !important;
        }

        // &__field:hover, &__field:active, &__field:focus,
        // &__field:hover + &__arrow,
        // &__field:active + &__arrow,
        // &__field:focus + &__arrow {
        //     opacity: 0.7;
        // }
    }

    &__input {
        // &__field:hover, &__field:active, &__field:focus {
        //     opacity: 0.7;
        // }
    }

    // captcha
    .captcha-wrapper {
        display: flex;
        align-items: center;
        gap: 1.6rem;

        img {
            height: 6rem;
            width: auto;
            border-radius: 10px;
            border-radius: 3rem;
        }
    }

    // errors
    .errorlist {
        position: absolute;
        bottom: -2em; 
        width: 100%;
        
        font-size: 0.9em;
        letter-spacing: 0.005em;
        line-height: 1;
        color: $orange;
    }
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 1;
}

::-moz-placeholder {
    color: inherit;
    opacity: 1;
}

::-ms-placeholder {
    color: inherit;
    opacity: 1;
}

::placeholder {
    color: inherit;
    opacity: 1;
}

// select2 customization - FIELD

.select2-container--default {
    width: auto !important;
}

.select2-container--default .select2-selection--single {
    background-color: transparent !important;
    border-width: 0 !important;
    padding-right: 2rem !important; // arrow space
}

.select2-selection__arrow {
    display: none !important;
}

.select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    color: $blue !important;
    padding-left: 0 !important;
    overflow: visible !important;
}

.select2-container--open
    .select2-selection--single
    .select2-selection__rendered,
.select2-container--default
    .select2-selection--single
    .select2-selection__placeholder {
    color: $cream !important;
}

// select2 customization - DROPDOWN

.select2-dropdown {
    border: 0 !important;
    color: $blue !important;
    background: $cream !important;
    border-radius: 5px !important;
    margin-top: 1rem !important;
    overflow: hidden !important; // hide scrollbar radius
    // min-width: 20rem;
    min-width: 20rem !important;

    &--above {
        transform: translateY(-1.5rem);
    }
}

.select2-container--default .select2-results > .select2-results__options {
    max-height: 30rem !important;
}

.select2-container--open .select2-dropdown--below {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.select2-results__option--selectable,
.select2-results__option {
    // margin: 3px!important;
    // border-radius: 5px!important;
    border-radius: 0 !important;
    min-width: max-content !important;
    line-height: 0.8;
    padding: 7px !important;
}

.select2-container--default
    .select2-results__option--highlighted.select2-results__option--selectable {
    background: $blue !important;
    color: $orange !important;
}

.alt-select-colors {
    .select2-container--default
    .select2-results__option--highlighted.select2-results__option--selectable {
        background: $orange !important;
        color: $blue !important;
    }

    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
        color: $orange !important;
    }
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid $blue !important;
    border-radius: 5px !important;
}

.select2-container--default .select2-selection--single {
    background-color: transparent !important;
    border-width: 0 !important;
}

.select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    overflow: visible !important;
}

.select2-container--default
    .select2-selection--single
    .select2-selection__placeholder {
    color: $cream !important;
}
