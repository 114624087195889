.single-viatge {
    // portada

    .s0 {
        .cover {
            &__content {
                margin-top: 3.5vw;
                width: 60%;
                max-width: 31em;

                &__title {
                    margin-top: 0.25em;
                    margin-bottom: 0.05em;
                    margin-left: -0.06em; // weirdness
                }

                &__highlight {
                    padding-left: 0;
                    width: 45vw;
                }
            }
        }

        @media #{$media-mobile} {
            .cover__content {
                padding-top: $pad-xt;
            }
        }
    }

    // dades

    .s00 {
        padding-top: 0;
        padding-bottom: 0;
        min-height: $dades-h;
        display: flex;
        align-items: center;

        &__wrap {
            display: flex;
            align-items: center;
            align-items: flex-start;
            grid-gap: unquote("max(3em, 5vw)");
            margin-bottom: 1em; // space for buttons

            h3 {
                font-size: $f-45-dades;
                letter-spacing: -0.011em;

                padding: 0.3em 0 0.5em 0;
            }
        }

        &__dada {
            max-width: 20vw;

            &__nivelldetrek {
                transition: color 0.3s ease;
                text-decoration: none !important;

                svg {
                    circle {
                        transition: fill 0.3s ease;
                    }
                }

                &:hover,
                &:active,
                &:focus {
                    color: $orange;
                    svg {
                        circle {
                            fill: $orange;
                        }
                    }
                }
            }
        }

        &__trekking {
            position: fixed;
            z-index: 999;

            bottom: 4vh;
            right: 0;
            width: 26vw;
            background-color: $cream;
            border-top-left-radius: 0.667em;
            border-bottom-left-radius: 0.667em;
            color: $blue;
            padding: 1.8em 1.5em;

            &__title {
                font-family: inherit;
                font-size: $f-32;
                line-height: 1.25;
                letter-spacing: -0.011em;
            }
            &__text {
                margin-top: 0.8em;

                font-size: $f-26;
                line-height: 1.15;
                letter-spacing: -0.011em;
            }
            &__close {
                position: absolute;
                top: 0;
                transform: translateY(-50%);
                left: 1.5em;
                width: 2em;
            }

            // ui
            transform: translateX(101%);
            transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

            &.show {
                transform: translateX(0);
            }
        }

        @media #{$media-mobile} {
            &__wrap {
                flex-direction: column;
                padding: 5rem $pad-m 2rem $pad-m;
                text-align: center;
                align-items: center;
                grid-gap: 1em;
                width: 100%;
                max-width: 34rem;
                margin: 0 auto;

                h3 {
                    font-size: $f-45-dades;
                    letter-spacing: -0.011em;

                    padding: 0.3em 0 0.5em 0;
                }
            }

            &__dada {
                max-width: unset;
                width: fit-content;
            }

            margin-bottom: -2px; // bug mobile two sections
        }
    }

    // mapa

    .s1 {
        min-height: 50vw;
        position: relative;
        padding-right: 0; // cta

        overflow: visible;
        z-index: 2;

        &__buttons {
            position: absolute;
            z-index: 2;
            top: 0;
            transform: translateY(-50%);
            display: flex;

            border-width: 0; // buttons effect better

            .btn {
                margin-right: 2.4rem;
            }

            .btn:last-of-type {
                margin-right: 0;
            }
        }

        &__wrap {
            z-index: 1;
            position: relative;
            max-width: 33vw; // REV // ancho text

            .titol {
                margin-top: 1.5em;
                margin-bottom: 0.88em;

                &.sortides {
                    margin-top: 0;
                    margin-bottom: 0.5em;
                }
            }

            &__destacat {
                color: $midgrey;

                font-size: $f-40;
                letter-spacing: -0.033em;
                line-height: calc(45 / 40);

                p {
                    margin-bottom: 0.5em;
                }

                margin-bottom: 1.5em;
            }
            &__text {
                font-size: $f-32;
                letter-spacing: -0.0112em;
                line-height: calc(40 / 32);

                p {
                    margin-bottom: 0.5em;
                }

                margin-bottom: 2.3em;

                &.sortides {
                    width: 40vw;
                    margin-bottom: 0;
                }
            }

            &__sortides {
                padding-top: $pad-top;
                margin-top: 1em; // bug disturbing
            }

            &__btn.loading {
                animation-duration: 2s;
                animation-name: loader;
                animation-iteration-count: infinite;
                background-color: $blue;
                border-color: $blue;
                color: white;
            }

            @keyframes loader {
                50% {
                    background-color: $orange;
                    border-color: $orange;
                }
            }
        }

        .cover__image {
            object-fit: contain;
            padding: 4.5rem 4.5rem 9rem 0; // inset
        }

        &__cta {
            position: absolute;
            height: fit-content;
            z-index: 2;
            transform: translateY(50%);
            right: 0;
            bottom: 0;
            width: max-content;

            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 2rem 3rem;

            background-color: $blue;
            color: white;

            border-top-left-radius: 2.5rem;
            border-bottom-left-radius: 2.5rem;

            &__text {
                font-size: $f-50;
                letter-spacing: -0.0112em;
                padding-bottom: 0.3em;
            }

            .btn {
                padding: 0 1em;
            }

            // adaptation to dark background color
            transition: background-color 0.4s ease;

            &.orange {
                background-color: $orange;

                .btn--orange {
                    background-color: $blue;
                }
            }
        }

        &__cta.fix {
            position: fixed;
            top: -40px;
            z-index: 10;
        }

        @media #{$media-tablet} {
            &__wrap {
                &__sortides {
                    padding-top: $pad-yt;
                }
            }
        }

        @media #{$media-mobile} {
            padding-top: 0;
            padding-left: 0;
            padding-bottom: 0;

            &__buttons {
                position: relative;
                transform: none;
                background-color: $blue;
                // padding-top: $pad-yt;
                // padding-bottom: $pad-yt;
                flex-wrap: wrap;
                justify-content: center;

                .btn {
                    margin: calc(0.5 * #{$pad-m});
                    font-size: $f-26;
                }

                padding: $pad-xt calc(0.5 * #{$pad-m}) 5rem
                    calc(0.5 * #{$pad-m});
            }

            &__wrap {
                padding-top: $pad-m;
                padding-left: $pad-m;
                padding-right: $pad-m;
                max-width: unset;
            }

            .cover__image {
                padding: $pad-yt $pad-m;
                height: unset;
            }

            &__cta {
                position: fixed;
                z-index: 500;
                width: 100%;
                border-radius: 0;
                bottom: 0;
                padding: 1rem;
                flex-direction: row;
                flex-wrap: wrap;

                &__text {
                    font-size: $f-40;
                }

                transform: translateY(120%);
                opacity: 0;
                transition: all 0.4s ease-in-out;

                &.show {
                    transform: none;
                    opacity: 1;
                }

                &.fix {
                    top: unset;
                    z-index: 500;
                }
            }
        }
    }

    // punts itinerari

    .s2 {
        padding-right: 32vw;

        &__wrap {
            display: flex;
            flex-direction: column;

            &__item {
                display: grid;
                grid-template-columns: 4.5em 1fr;
                border-bottom: 1px solid $blue;

                padding-top: 1rem;
                padding-bottom: calc(1rem - 0.3em);
                line-height: 1.15;

                // // balls
                // position: relative;
                // .dia::after {
                //     content: '';
                //     border-right: 1px solid $blue;
                //     position: absolute;
                //     left: 10rem;
                //     width: 1px;
                //     bottom: calc(3rem - 0.05em);
                //     top: 3rem;
                // }
            }

            // &__item::after {
            //     position: absolute;
            //     content: '';
            //     width: 1.6rem;
            //     height: 1.6rem;
            //     background: $blue;
            //     border-radius: 50%;
            //     right: 0;
            //     bottom: 0;
            //     transform: translateY(50%);
            // }

            &__item:last-of-type {
                border-width: 0;
            }

            // &__item:last-of-type::after {
            //     display: none;
            // }
        }

        @media #{$media-mobile} {
            padding-right: $pad-m;
        }
    }

    // preus + inclou + covid

    .s3 {
        font-size: $f-30;
        letter-spacing: 0;

        &__wrap {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10vw;

            &__inclou__si,
            &__inclou__no {
                // width: 76%;
                margin-bottom: 4.2vw;
            }

            &__preus {
                // + + + + + + + + + + + +
                // more styling @pricetable
                // + + + + + + + + + + + +

                &__nota {
                    font-size: $f-25;
                }
            }

            &__inclou {
                margin-left: 4rem;

                &__si__item,
                &__no__item {
                    margin-bottom: 1.3rem;
                    line-height: 1.15;
                    position: relative;
                }

                &__si__item::before,
                &__no__item::before {
                    content: "";
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;

                    position: absolute;
                    left: -2em;
                    width: 1.3em;
                    height: 1em;
                }

                &__si__item::before {
                    background-image: url("../img/items-check.svg");
                    top: 0.02em; // correction to center vert.
                }

                &__no__item::before {
                    background-image: url("../img/items-no.svg");
                    top: 0.04em; // correction to center vert.
                }
            }
        }

        &__covid {
            line-height: 4.1rem;

            p {
                margin-bottom: 0.8em; // adds also to section padding below
            }
        }

        @media #{$media-mobile} {
            &__wrap {
                grid-template-columns: 1fr;
                gap: 4em;

                &__inclou {
                    .titol {
                        margin-left: -4rem;
                    }

                    &__no {
                        margin-top: 5rem;
                    }
                }
            }
        }
    }

    // comentaris

    .s3bis {
        position: relative;

        &__wrap {
            padding: 0 $pad-left;
            margin: 0 $pad-left;
            text-align: center !important;

            blockquote {
                line-height: calc(35 / 30);
                letter-spacing: 0;
            }
            figcaption {
                padding-top: 2em;
            }
        }

        .swiper {
            &__nav {
                user-select: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                &__right,
                &__left {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);

                    height: 3rem;
                    width: 4rem;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;

                    cursor: pointer;
                    display: inline-block;
                }
                &__right {
                    background-image: url("../img/arrow-right-blue.svg");
                    right: $pad-right;
                    margin-right: $pad-left;
                }
                &__left {
                    background-image: url("../img/arrow-left-blue.svg");
                    left: $pad-left;
                    margin-left: $pad-left;
                }
            }
        }

        @media #{$media-laptop} {
            &__wrap {
                padding: 0 $pad-left;
                margin: 0 $pad-xt;
            }
            .swiper {
                &__nav {
                    &__right {
                        right: $pad-xt;
                        margin-right: $pad-left;
                    }
                    &__left {
                        left: $pad-xt;
                        margin-left: $pad-left;
                    }
                }
            }
        }

        @media #{$media-tablet} {
            .swiper {
                &__nav {
                    &__right {
                        margin-right: $pad-xt;
                    }
                    &__left {
                        margin-left: $pad-xt;
                    }
                }
            }
        }

        @media #{$media-mobile} {
            &__wrap {
                margin: $pad-m;
                padding: 0;
            }

            .swiper {
                &__nav {
                    position: static;
                    height: auto;
                    text-align: center;
                    margin-top: 4rem;

                    &__right,
                    &__left {
                        position: static;
                        margin: 0 1em !important;
                        transform: none;
                    }
                }
            }
        }
    }

    // a mida

    .s4 {
        &__wrap {
            display: grid;
            grid-template-areas:
                "destacat buttons"
                "text buttons";
            grid-template-columns: 31em 1fr;
            grid-gap: 0 $pad-right;

            &__destacat {
                grid-area: destacat;
                margin-bottom: 0.65em;

                font-size: $f-100;
                letter-spacing: -0.033em;
                line-height: calc(120 / 115);
            }

            &__text {
                grid-area: text;

                font-size: $f-40;
                letter-spacing: -0.033em;
                line-height: calc(45 / 40);

                max-width: 23em;
            }

            &__buttons {
                grid-area: buttons;
                justify-self: center;

                .btn {
                    display: block;
                    width: 9.2em;
                    height: 2.1em;
                    line-height: 2.1em;
                    margin-bottom: 0.6em;
                    border-radius: 2.1em;
                    padding: 0;

                    font-size: $f-40-btn;
                    letter-spacing: -0.04em;
                    font-weight: normal;
                }

                .btn--orange:hover {
                    border-color: $blue;
                    background-color: $blue;
                    color: $cream;
                }
            }
        }

        @media #{$media-laptop} {
            &__wrap {
                grid-template-columns: auto 1fr;
            }
        }

        @media #{$media-laptop-sm} {
            &__wrap {
                grid-gap: 0 3em;
            }
        }

        @media #{$media-mobile} {
            &__wrap {
                display: block;

                &__buttons {
                    margin-top: 3rem;
                    text-align: center;

                    .btn {
                        display: inline-block;
                        margin: 0.3em;
                    }
                }
            }
        }
    }

    // viatge a

    .s5 {
        padding-top: $pad-top;
        padding-bottom: $pad-top;

        .titol {
            padding-right: $pad-right;
            padding-left: $pad-left;
        }

        // + + + + + + + + + + + +
        // more styling @carousel
        // + + + + + + + + + + + +

        @media #{$media-laptop} {
            padding-top: $pad-top-l;
            padding-bottom: $pad-top-l;

            .titol {
                padding-right: $pad-right-l;
                padding-left: $pad-left-l;
            }
        }

        @media #{$media-tablet} {
            padding-top: $pad-yt;
            padding-bottom: $pad-yt;

            .titol {
                padding-right: $pad-xt;
                padding-left: $pad-xt;
            }
        }

        @media #{$media-mobile} {
            padding-top: $pad-yt;
            padding-bottom: $pad-yt;

            .titol {
                padding-right: $pad-m;
                padding-left: $pad-m;
            }
        }
    }
}
