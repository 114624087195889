/* arrow animation */

.arrow-animate-right, .arrow-animate-left {
    // animation-timing-function:ease-in;
    transition: transform 0.5s ease;
}

// .arrow-animate-left:hover { animation: arrow-left 1s infinite alternate; }
// .arrow-animate-right:hover { animation: arrow-right 1s infinite alternate; }

// @keyframes arrow-left {
//     0%   { transform: translateX(0); }
//     100%  { transform: translateX(-40%); }
// }
  
// @keyframes arrow-right {
//     0%   { transform: translateX(0); }
//     100%  { transform: translateX(40%); }
// }
  

.arrow-animate-left:hover { transform: translateX(-40%); }
.arrow-animate-right:hover { transform: translateX(40%); }
