.grid-viatges {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    padding-bottom: 6vw;
    margin-top: -3.5rem; // compensate top margin cards

    @media #{$media-tablet} {
        display: block;
        margin-top: 0;
    }

    @media #{$media-mobile} {
        margin-top: -1rem;
    }

    &__item {
        position: relative;

        &__bgwrap {
            width: 100%;
            height: 33vw;
        }
    }

    .card {
        &__slide {
            width: 100%;
            height: 33vw;
            margin-top: 5rem;
        }

        &__bgwrap {
            width: 100%;
            height: 33vw;
        }

        // responsive
        @media #{$media-laptop} {
            &__slide,
            &__bgwrap {
                height: 40vw;
            }
        }

        @media #{$media-laptop-sm} {
            &__slide,
            &__bgwrap {
                height: 38vw;
            }
        }

        @media #{$media-tablet} {
            &__slide {
                display: inline-block;
                margin-right: $pad-xt;
                margin-top: 2rem;
                width: 29vw;
            }
        }

        @media #{$media-mobile} {
            &__slide {
                margin-top: 0;
                margin-bottom: 4.5rem;
                // height: 31rem;
                // width: 25rem;

                height: calc(130vw - 2.5 *#{$pad-m});
                max-height: 85vh;
                width: calc(100vw - 2 *#{$pad-m});
            }

            &__bgwrap {
                border-radius: $pad-m;
                // height: 31rem;
                // width: 25rem;

                height: calc(130vw - 2.5 *#{$pad-m});
                max-height: 85vh;
                width: calc(100vw - 2 *#{$pad-m});
            }

            &__content {
                padding: $pad-m;
            }

            &__price {
                margin-left: $pad-m;
            }
        }
    }
}
