.page-sol-en-grup {
    .s0 {
        @media #{$media-mobile} {
            .cover__content {
                padding-bottom: $pad-xt;
            }
        }
    }
    .s1 {
        &__text {
            max-width: 58%;
        }

        &__wrap {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 7rem;
            margin-left: 4rem;
            grid-gap: 7rem;

            // + + + + + + + + + + + +
            // more styling @base -> textblock
            // + + + + + + + + + + + +

            .textblock {
                &__text {
                    font-size: $f-32;
                    letter-spacing: -0.01em;
                    line-height: calc(40 / 32);
                    margin-bottom: 0;
                }
            }
        }

        @media #{$media-tablet} {
            &__wrap {
                margin-left: 0;
            }
        }

        @media #{$media-mobile} {
            &__text {
                max-width: unset;
            }
            &__wrap {
                grid-template-columns: 1fr;
                margin-bottom: 5rem;
            }
        }
    }

    .s2 {
        .titol {
            // margin-bottom: 0.4em;
            display: flex;
            align-items: center;
        }
    }
}
