.page-news {
    .s0 {
        .cover__content {
            width: unset;
        }

        .newslist {
            margin-top: 4rem;
            margin-bottom: $pad-bottom;

            // + + + + + + + + + + + +
            // more styling @newslist
            // + + + + + + + + + + + +
        }
    }
}
