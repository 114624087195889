.restyle {
    // > :first-child {
    //     margin-top: 5rem;
    // }


    h1 {
        font-size: 7.5rem;
        font-weight: $ultralight;
        margin-bottom: 0;
        line-height: 0.9;
    }

    h2 {
        font-size: 3rem;
        letter-spacing: -0.025em;
        padding-top: 1.6rem;
        font-size: inherit;
    }
    
    p {
        font-size: 1.4rem;
        letter-spacing: -0.025em;
        line-height: 1.2; // 1.7rem
        margin: 0;
        padding-top: 1rem;
    }

    h2 {

    }

    // h1::after,
    // h2::after {
    //     content: '';
    //     display: block;
    //     height: 10rem;
    //     margin-bottom: -10rem;
    // }

    a {
        color: $orange !important;
        text-decoration: underline !important;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none !important;
        }
    }

    .hr-wrapper {
        padding: 3rem;
    }

    hr {
        margin: 0 auto;
        height: 0;
        width: 20rem;
        border-top: 0.5px solid $blue;
    }

    // listas

    ul {
        list-style-type: none;
        margin-left: 0;
        padding-top: 1rem;

        > li {
            position: relative;
        }

        > li:before {
            display: block;
            position: absolute;
            // content: url('../img/arrow-right-text.svg');
            content: '→';
            left: -2rem;
            // width: 8.2rem;
            // top: 0.45rem;
        }

        // all the same
        margin-left: 2rem;
    }

    ol {
        margin-left: 6rem;
        counter-reset: item;
        position: relative;

        li:before {
            content: counter(item, decimal) '. ';
            counter-increment: item;
            position: absolute;
            left: -3.5rem;
        }
    }

    // h1 + ul, h2 + ul,
    // h1 + ol, h2 + ol {
    //     padding-top: 0;
    // }

    .list-no-space {
        margin-top: 0;
    }

    ul.list-no-space,
    .fitxa-equipo-recomendado ul {
        li {
            font-size: 1.1rem;
            line-height: 1.6rem;
        }

        // > li:before {
        //     top: 0.4rem;
        // }
    }

    // tablas

    table table p {
        font-size: 1.1rem !important;
        line-height: 1.3rem;
        letter-spacing: 0;
        width: 100%;
        margin-bottom: 1rem;

        strong {
            font-weight: 400 !important;
        }

        tr {
            border-bottom: 1px solid $blue;
            td {
                padding-bottom: 0.7rem;
                padding-top: 0.7rem;
                vertical-align: top;
            }
        }

        p {
            margin-bottom: 0 !important;
        }

        &:not(.no-header) {
            tr:first-of-type,
            tr:first-of-type strong {
                color: $orange;
                font-weight: 600 !important;
                border-width: 0 !important;

                td {
                    padding-top: 2rem;
                }
            }
        }
    }
}
