.btn {
    border-radius: 2.4em;
    border-width: 1px;

    display: inline-block;
    cursor: pointer;
    width: fit-content;
    min-width: 7.6em;
    height: 2.4em;
    line-height: 2.4em;
    padding: 0 1.7em;
    border: 1px solid black;

    transition: all 0.4s ease;

    font-size: $f-25;
    letter-spacing: -0.016em;
    font-weight: 500;
    text-align: center;

    // stroke buttons

    &--stroke {
        border-color: $orange;
        background-color: white;
        color: $orange;

        &:hover,
        &:active,
        &:focus {
            border-color: $blue;
            background-color: $blue;
            color: $cream;
        }
    }

    // strokevit buttons

    &--strokevit {
        border-color: $orange_vit;
        background-color: white;
        color: $orange_vit;

        &:hover,
        &:active,
        &:focus {
            border-color: $blue;
            background-color: $blue;
            color: $cream;
        }
    }

    // orange buttons

    &--orange {
        border-color: $orange;
        background-color: $orange;
        color: $cream;

        &:not(:disabled) {
            &:hover,
            &:active,
            &:focus {
                border-color: $cream;
                background-color: $cream;
                color: $orange;
            }
        }

        &:disabled {
            opacity: 0.7;
            color: $blue;
            cursor: not-allowed;
        }
    }

    // orange-vit buttons

    &--orangevit {
        border-color: $orange_vit;
        background-color: $orange_vit;
        color: $cream;

        &:not(:disabled) {
            &:hover,
            &:active,
            &:focus {
                border-color: $cream;
                background-color: $cream;
                color: $orange_vit;
            }
        }

        &:disabled {
            opacity: 0.7;
            color: $cream;
            cursor: not-allowed;
        }
    }

    // light buttons

    &--cream {
        border-color: $cream;
        background-color: $cream;
        color: $orange;

        &:hover,
        &:active,
        &:focus {
            border-color: $blue;
            background-color: $blue;
            color: $cream;
        }
    }

    // light vit buttons

    &--creamvit {
        border-color: $cream;
        background-color: $cream;
        color: $orange_vit;

        &:hover,
        &:active,
        &:focus {
            border-color: $orange;
            background-color: $orange;
            color: $blue;
        }
    }

    // blue buttons

    &--blue {
        border-color: $blue;
        background-color: $blue;
        color: $cream;

        &:hover,
        &:active,
        &:focus {
            border-color: $orange;
            background-color: $orange;
            color: $cream;
        }
    }

    // price buttons

    &--price {
        font-size: $f-40-btn;
        letter-spacing: -0.04em;
        font-weight: normal;
        padding: 0;
        pointer-events: none; //avoid hover color change

        min-width: 5.3em;
        height: 1.6em;
        line-height: 1.6em;
    }
}
