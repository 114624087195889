#hamburger {
    // bigger clickable area
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 50%;
    left: 50%;
    transform: translate(-1rem, -0.9rem);

    margin-bottom: -1px;
    margin-top: 1px;

    transition: all 0.2s ease-out;
    cursor: pointer;

    /* Styling the hamburger lines */
    .line {
        width: 21px;
        height: 2px;
        margin: 0 0 5px 0;
        background: $cream;
        transition: all 0.2s ease-out;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &.expanded {
        /* Styling the three lines to make it an X */
        .line:nth-child(1) {
            transform: rotate(45deg) translate(5px, 5px);
        }
        .line:nth-child(2) {
            opacity: 0;
        }
        .line:nth-child(3) {
            transform: rotate(-45deg) translate(5px, -5px);
        }
    }
}
