// Media queries
$media-laptop: "only screen and (max-width : 1200px)";
$media-laptop-sm: "only screen and (max-width : 1024px)";
$media-tablet: "only screen and (max-width : 990px)";
$media-mobile: "only screen and (max-width : 767px)";
$media-mobile-sm: "only screen and (max-width : 420px)";

// Fonts
$font: "Armin Grotesk", Helvetica, sans-serif;
$font-alt: "Ariata Display", serif;
$thin: 100;
$regular: 400;
$semibold: 600;

// Colors
$orange: #EBB32C; // rgba(235, 179, 44, 100%);
$orange_vit: #dd4f34; // rgba(221, 79, 52, 100%);
$blue: #384F5D; // rgba(56, 79, 93, 100%);
$cream: #fef7f1;
$grey: #979797;
$lightgrey: $cream;
$midgrey: #a6a6a6;
$hightlightgray: #9d9d9c;

// Spacing
// $pad-top: 4vw;
// $pad-right: calc(7vw - 10px);
// $pad-bottom: 4.5vw;
// $pad-left: 7vw;
$pad-top: 6.5rem;
$pad-right: calc(12rem - 10px);
$pad-bottom: 6.3rem;
$pad-left: 11rem;

$pad-top-l: 4rem;
$pad-right-l: calc(8rem - 10px);
$pad-bottom-l: 4rem;
$pad-left-l: 7rem;

$pad-top-t: 4rem;
$pad-right-t: 3rem;
$pad-bottom-t: 4rem;
$pad-left-t: 3rem;
$pad-yt: 4rem;
$pad-xt: 3rem;

$pad-m: 1.5rem;

// $header-h: 11rem;
// $header-h-t: 6rem;
// $dades-h: 16rem;
$header-h: 14vh;
$header-h-t: 6rem;
$dades-h: 19vh;
$cover-padding-top: 8vh;
$cover-padding-bottom: 6vh;

//----------------------------------------------------------------
// Font-sizes
//
// - Normally we calculate variable part of font size as 0.6 of top rem value
// - This makes the maximum value be reached in screens of 1666px

$font-factor: 0.5vw;
$font-factor2: 0.45vw;
$font-factor3: 0.5vw;

$f-15: clamp(1.2rem, 1.6 * #{$font-factor}, 1.6rem);
$f-22: clamp(1.3rem, 2.2 * #{$font-factor}, 2.2rem);
$f-25: clamp(1.8rem, 2.5 * #{$font-factor}, 2.5rem);
$f-25-menu: clamp(1.4rem, 2.3 * #{$font-factor}, 2.5rem);
$f-25-menu-smaller: clamp(1.4rem, 1.7 * #{$font-factor}, 2.5rem);
$f-26: clamp(1.9rem, 2.6 * #{$font-factor}, 2.6rem);
$f-30: clamp(2rem, 3 * #{$font-factor}, 3rem);
$f-32: clamp(2.2rem, 3.2 * #{$font-factor}, 3.2rem);
$f-35: clamp(2.25rem, 3.5 * #{$font-factor}, 3.5rem);
$f-40: clamp(2.3rem, 4 * #{$font-factor}, 4rem);
$f-40-btn: clamp(2.3rem, 4 * #{$font-factor}, 3.2rem);
$f-45: clamp(2.7rem, 4.5 * #{$font-factor}, 4.5rem);
$f-45-dades: clamp(2.7rem, 4 * #{$font-factor}, 4.5rem);
$f-50: clamp(2.7rem, 5 * #{$font-factor}, 5rem);
$f-60: clamp(2.7rem, 6 * #{$font-factor2}, 6rem);
$f-65: clamp(3.1rem, 6.5 * #{$font-factor2}, 6.5rem);
$f-70: clamp(3.5rem, 7 * #{$font-factor2}, 7rem);
$f-75: clamp(3.8rem, 7.5 * #{$font-factor2}, 7.5rem);
$f-85: clamp(4.3rem, 8.5 * #{$font-factor2}, 8.5rem);
$f-100: clamp(4.5rem, 10 * #{$font-factor3}, 10rem);

$regular: 500;
$semibold: 600;
$ultralight: 200;
