.page-results {
    .s0 {
        padding-top: 12rem;
        padding-bottom: 0;

        @media #{$media-tablet} {
            padding-top: 9rem;
        }
    }

    .s0bis {
        padding-top: 0.3rem;
        padding-bottom: 2rem;

        .form {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 6rem;

            font-size: $f-25-menu;
            letter-spacing: -0.029em;
            line-height: 6.3rem;

            &__wrapper {
                display: flex;
                align-items: center;
                gap: 2em;
                margin-left: 2rem; // arrow space
                width: 100%;
                justify-content: space-around;
                margin-right: 10rem;
            }

            &__select,
            .btn {
                margin: 0;
                font-size: $f-25-menu;

                .select2-container--default .select2-selection--single {
                    padding-right: 0.5em;
                }
            }

            &__select::before,
            &__input::before {
                left: -4rem;
                width: 2.5rem;
            }

            &__select {
                &__arrow {
                    right: 1.5rem;
                    width: 1.2rem;
                }
            }
        }

        @media #{$media-tablet} {
            .form {
                &__select {
                    font-size: $f-26;
                    letter-spacing: -0.011em;
                }

                .btn {
                    font-size: $f-26;
                    letter-spacing: -0.011em;
                    // margin-bottom: 1em;
                }
            }
        }

        @media #{$media-mobile} {
            .form {
                grid-template-columns: 1fr;
                gap: 2rem;

                &__wrapper {
                    flex-direction: column;
                    align-items: start;
                    margin-left: 4.5rem;
                    gap: 1em;
                    margin-bottom: 1em;
                }

                &__select {
                    font-size: $f-30;
                    letter-spacing: -0.029em;
                    line-height: calc(35 / 30);
                }

                .btn {
                    font-size: $f-26;
                    letter-spacing: -0.011em;
                    margin-bottom: 1em;
                }
            }
        }
    }

    .select2-dropdown {
        font-size: $f-25-menu-smaller;
        min-width: 15rem !important;
    }

    .s1 {
        .no-results {
            margin-top: 4rem;
        }
    }

    .result-select {
        margin-left: 0;
    }
}
