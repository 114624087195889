// Basics ----------------
@import "reset";
@import "fonts";
@import "variables";
@import "base";

// Includes ----------------
@import "includes/footer";
@import "includes/header";
@import "includes/carousel";
@import "includes/forms";
@import "includes/grid-viatges";
@import "includes/cookie-banner";

// Special ----------------
@import "special/cover";
@import "special/buttons";
@import "special/cards";
@import "special/newslist";
@import "special/results";
@import "special/hamburger";
@import "special/restyling";
@import "special/pricetable";
@import "special/form-positive";

// Animations
@import "special/animations/arrows";

// Pages ----------------
@import "pages/landing";
@import "pages/results";
@import "pages/about";
@import "pages/contact";
@import "pages/news";

// Singles
@import "pages/singles/viatge";
@import "pages/singles/pais";
@import "pages/singles/noticia";
@import "pages/singles/pdf";
@import "pages/singles/sol-en-grup";
@import "pages/singles/legal";
