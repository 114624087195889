.cover {
    // heights
    &--smaller {
        //viatge
        min-height: calc(100vh - #{$header-h} - #{$dades-h});
    }
    &--small {
        height: calc(100vh - #{$header-h});
        min-height: 46vw;
    }
    &--large {
        min-height: 100vh;
    }

    position: relative;
    display: block;

    padding-top: $header-h; // space for header
    padding-bottom: 3vw;

    overflow: hidden; // correct bug image white line

    &__image {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 46%;
        height: 100.5%; // correct bug image white line
        object-fit: cover;
    }

    &__content {
        margin-left: $pad-left;
        margin-top: $cover-padding-top;
        width: 70%;
        z-index: 1;
        position: relative;

        &__breadcrumbs {
            font-size: $f-25;
            font-weight: 500;
            letter-spacing: -0.0114em;
        }

        &__title {
            margin-top: 0.5rem;
            margin-bottom: 1.7rem;
            // width: 90rem;
        }

        &__highlight {
            font-size: $f-50;
            letter-spacing: -0.0334em;
            line-height: calc(6 / 5);
            max-width: 40vw;
            padding-left: $pad-left;
        }
    }

    // responsive

    @media #{$media-laptop} {
        &__content {
            margin-left: $pad-left-l;
        }
    }

    @media #{$media-tablet} {
        padding-top: $header-h-t; // space for header

        // heights
        &--smaller {
            //viatge
            min-height: calc(100vh - #{$header-h-t} - #{$dades-h});
        }
        &--small {
            min-height: calc(100vh - #{$header-h-t});
        }

        &__content {
            margin-top: $pad-yt;
            margin-left: $pad-xt;

            &__highlight {
                max-width: 43vw;
                padding-left: $pad-xt;
            }
        }
    }

    @media #{$media-mobile} {

        &:not(.keep-mobile) {
            height: unset !important;
            min-height: unset !important;
            padding-bottom: 0;
        }

        &__image:not(.keep-mobile)  {
            position: relative;
            width: 100%;
            height: 120vw;
        }

        &__content {
            // margin-top: $pad-m;
            margin-left: $pad-m;
            margin-right: $pad-m;
            // margin-bottom: $pad-m;

            padding: $pad-m 0;

            width: unset !important;
            max-width: unset !important;

            &__highlight {
                max-width: unset !important;
                width: unset !important;
                padding-left: 0 !important;
                margin: 1em 0 0.7em 0;
            }
        }
    }
}
