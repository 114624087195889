.page-landing {
    .s0 {
        // + + + + + + + + + + + +
        // more styling @cover
        // + + + + + + + + + + + +

        .cover {
            &__content {
                width: 60%;
                max-width: 31em;

                h1 {
                    margin: $cover-padding-top 0 $cover-padding-bottom 0;
                }

                .form {
                    &__select {
                        margin-bottom: .62em;
                        margin-bottom: 3vh;
                        margin-bottom: unquote("max(3vh,0.62em)");
                    }
    
                    .btn {
                        margin-bottom: 1rem;
                        margin-bottom: 2vh;
                        margin-bottom: unquote("max(2vh,1rem)");
                    }
                }
            }
            &__image {
                @media #{$media-laptop} {
                    width: 40%;
                }

                @media #{$media-tablet} {
                    width: 38%;
                }

                @media #{$media-mobile} {
                    width: 100%;
                    object-fit: cover;
                }
            }

        }

        .form {
            margin: 5rem 0 6rem $pad-left;

            @media #{$media-mobile} {
                margin: 4rem 0 5rem 5.3rem;
            }

            // + + + + + + + + + + + +
            // more styling @forms --> .form
            // + + + + + + + + + + + +
        }

        @media #{$media-mobile} {
            .cover {
                &__content {
                    width: unset;
                }
            }
        }
    }

    .s00 {
        display: grid;
        grid-template-columns: repeat(4, max-content);
        min-height: $header-h;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;
        justify-content: center;
        grid-gap: 2em;

        font-size: $f-45-dades;
        letter-spacing: -0.0111em;

        @media #{$media-mobile} {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(4, max-content);
            padding-top: $pad-yt;
            padding-bottom: $pad-yt;
            justify-items: center;
            grid-gap: 1.5em;
        }
    }

    .s1 {
        padding-top: $pad-top;
        padding-bottom: $pad-top;

        .titol {
            padding-right: $pad-right;
            padding-left: $pad-left;
        }

        &__wrap {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 8%;
            align-items: end;
            justify-content: start;

            margin: 1em $pad-right 2em $pad-left;

            &__destacat {
                font-size: $f-70;
                letter-spacing: -0.033em;
                line-height: 1;
                margin-bottom: 0.1em; // in order to algin button to letter "P"
                max-width: 11.5em;
            }
        }

        // + + + + + + + + + + + +
        // more styling @carousel
        // + + + + + + + + + + + +

        @media #{$media-laptop} {
            padding-top: $pad-top-l;
            padding-bottom: $pad-bottom-l;

            .titol {
                padding-left: $pad-left-l;
                padding-right: $pad-right-l;
            }

            &__wrap {
                margin-right: $pad-right-l;
                margin-left: $pad-left-l;
            }
        }

        @media #{$media-tablet} {
            padding-top: $pad-yt;
            padding-bottom: $pad-yt;

            .titol {
                padding-right: $pad-xt;
                padding-left: $pad-xt;
            }

            &__wrap {
                margin-right: $pad-xt;
                margin-left: $pad-xt;
            }
        }

        @media #{$media-mobile} {
            .titol {
                padding-right: $pad-m;
                padding-left: $pad-m;
            }

            &__wrap {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr auto;
                grid-gap: 3.5em;
                margin: 1em $pad-m 2em $pad-m;
            }
        }
    }

    .s2 {
        &__wrap {
            display: flex;
            align-items: center;
            margin-top: 1em;
            gap: 5%;

            &__lema {
                flex-grow: 0;
                flex-shrink: 0;
                width: 3em;
                margin-right: 6%; // bigger space btw text-hightlight than text-circle
                margin-bottom: 0.5em;

                font-size: $f-100;
                letter-spacing: -0.0333em;
                line-height: calc(120 / 135);
            }

            &__text {
                flex-grow: 1;
                margin: 0 3rem;

                font-size: $f-65;
                letter-spacing: -0.0333em;
                line-height: calc(75 / 65);
            }

            &__circle {
                // small font
                font-size: $f-30;
                letter-spacing: -0.029em;
                line-height: 1;

                flex-grow: 0;
                flex-shrink: 0;

                clip-path: circle(50%);
                width: 10em;
                height: 10em; // there is a small bug in circle sometimes (firefox...)

                background-color: $orange;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 1em 0.5em;

                &__big {
                    font-size: $f-50;
                    letter-spacing: -0.0112em;
                    line-height: 1;

                    color: $cream;

                    margin-bottom: 0.6em;
                }

                &__small {
                    font-weight: $regular;

                    // hover animation
                    transition: color 0.6s ease;
                }

                // hover animation
                transition: background-color 0.6s ease;
                &:hover {
                    background-color: $blue;
                    .s2__wrap__circle__small {
                        color: $orange;
                    }
                }
            }
        }

        @media #{$media-laptop} {
            &__wrap {
                &__lema {
                    margin-right: 2rem;
                }
                &__text {
                    margin: 0;
                    font-size: $f-40;
                    line-height: calc(50 / 40);
                    letter-spacing: -0.011em;
                }
            }
        }

        @media #{$media-tablet} {
            &__wrap {
                flex-wrap: wrap;

                &__lema {
                    margin-top: -0.4em;
                }

                &__text {
                    width: 10em;
                }

                &__circlecontainer {
                    width: 100%;
                    margin: 3em 0;
                }

                &__circle {
                    margin: 0 auto;
                    width: 15em;
                    height: 15em;
                }
            }
        }

        @media #{$media-mobile} {
            &__wrap {
                flex-direction: column;
                align-items: flex-start;
                gap: 1em;

                &__text {
                    margin: 0;
                    width: unset;
                }

                &__circle {
                    align-self: center;
                    // margin: 2rem auto;
                }
            }
        }
    }

    .s3 {
        padding-right: 0; // allow slides to appear from the very edge

        &__wrap {
            // display: grid;
            grid-template-columns: 1fr 42vw;

            cursor: default;
            overflow: hidden;

            height: 30vw;

            &__menu {
                margin-right: 4vw;
                margin-top: 1em;

                &__item {
                    font-family: $font;
                    font-size: clamp(4.5rem, 8 * 0.6vw, 10rem);
                    letter-spacing: -0.0286em;
                    line-height: 0.85;
                    padding-bottom: 0.2em;
                    position: relative;
                    width: fit-content;

                    a {
                        color: inherit !important;
                        text-decoration: none !important;
                    }
                }

                &__item::after {
                    display: inline-block;
                    position: relative;
                    content: "";
                    background-image: url("../img/arrow-right-blue.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 0.5em;
                    width: 0.45em;
                    left: 0.2em;
                    bottom: 0;
                }

                &__item:hover {
                    color: $orange;
                }

                &__item:hover::after {
                    background-image: url("../img/arrow-right-orange.svg");
                }

                // animation
                &__item,
                &__item::after {
                    transition: all 0.4s ease;
                }
                &__item:hover::after {
                    transform: translateX(40%);
                }
            }

            &__contents {
                position: relative;

                &__layer {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    right: -140%;

                    display: grid;
                    grid-template-columns: auto 1fr;

                    transition: all 0.7s cubic-bezier(0.53, 0.05, 0.24, 0.98);
                    background-color: white;

                    &__image {
                        width: 18vw;
                        height: 100%;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                    &__text {
                        font-size: $f-35;
                        letter-spacing: -0.011em;
                        line-height: 1;

                        margin: 0 4rem 2.5rem 2rem;
                        align-self: end;
                    }
                }
            }
        }

        @media #{$media-laptop} {
            &__wrap {
                grid-template-columns: 1fr 54vw;
                height: 42vw;

                &__menu {
                    max-width: 14em;

                    &__item {
                        padding-bottom: 0.7em;
                    }
                }
                &__contents {
                    &__layer {
                        &__image {
                            width: 26vw;
                        }
                    }
                }
            }
        }

        @media #{$media-tablet} {
            padding-left: 0;

            .titol {
                padding-left: $pad-xt;
            }

            &__wrapmobile {
                .swiper-slide {
                    width: fit-content;
                    margin-left: $pad-xt;

                    &:last-of-type {
                        margin-right: $pad-xt;
                    }
                }
                .card {
                    &__content {
                        color: $blue;

                        &__viatge-titol {
                            padding-bottom: 0.5em;
                        }
                    }

                    &__slide {
                        height: 60rem;
                    }

                    &__bgwrap {
                        height: 60rem;
                    }
                }

                .carousel__nav {
                    margin-top: 1em;
                    margin-left: $pad-xt;
                }
            }
        }

        @media #{$media-mobile} {
            .titol {
                padding-left: $pad-m;
            }

            &__wrapmobile {
                .swiper-slide {
                    margin-left: $pad-m;

                    &:last-of-type {
                        margin-right: $pad-m;
                    }
                }
                .card {
                    &__slide {
                        height: 60rem;
                        height: 80vh;
                    }

                    &__bgwrap {
                        height: 60rem;
                        height: 80vh;
                    }
                }

                .carousel__nav {
                    margin-top: 1em;
                    margin-left: $pad-m;
                }
            }
        }
    }

    .s4 {
        &__destacat {
            font-size: $f-70;
            letter-spacing: -0.0333em;
            line-height: calc(105 / 100);
            margin-bottom: 2.2em;
            margin-top: 1em;
            max-width: 15em;
        }

        &__wrap {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-left: 24rem;
            grid-gap: 7rem;

            // + + + + + + + + + + + +
            // more styling @base -> textblock
            // + + + + + + + + + + + +

            .textblock__text {
                margin-bottom: 0;
            }
        }

        @media #{$media-laptop} {
            &__wrap {
                margin-left: 0;
                margin-bottom: 4rem;
            }
        }
        @media #{$media-mobile} {
            &__wrap {
                grid-template-columns: 1fr;
                grid-gap: 4rem;
            }

            &__destacat {
                margin-top: 0;
            }
        }
    }

    .s5 {
        overflow: hidden;
        .newslist {
            overflow: hidden;
            margin-top: 2em;
            margin-bottom: 1em;

            // + + + + + + + + + + + +
            // more styling @newslist
            // + + + + + + + + + + + +
        }
    }

    .s6 {
        padding-bottom: 15rem;

        &__wrap {
            display: grid;
            grid-gap: unquote("max(6vw, 7rem)");

            grid-auto-flow: column;
            justify-content: start;

            margin-top: 5rem;

            &__logo {
                height: unquote("max(3.1vw, 3.5rem)");
                width: auto;
            }
        }

        @media #{$media-mobile} {
            &__wrap {
                grid-template-columns: 1fr;
                grid-gap: 1.5em;
                grid-auto-flow: unset;

                &__logo {
                    height: 2.2em;
                }
            }

            padding-bottom: 7rem;
        }
    }
}
