#CookielawBanner,
#RejectableCookielawBanner {
    background: $cream;
    color: $blue;
    padding: 15px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    font-family: inherit;
    font-size: 2rem;
    z-index: 99;
    width: 100%;

    .cookies {
        position: relative;
        padding: 1vw 6.3vw;

        h6 {
            font-weight: 500;
            // font-size: 1.35em;
            font-size: 2.5rem;
            margin-bottom: 0.6em;
        }

        &__flex {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                padding-bottom: 0;
                display: block;
                margin-right: 3vw;
                line-height: 1.25;
            }

            &__buttons {
                display: flex;
                align-items: center;
            }

            a {
                margin: 1rem;
                flex-shrink: 0;

                border-radius: 1.5em;
                border: 1px solid $blue;

                padding: 0 1em;
                line-height: 2.5em;
                height: 2.5em;

                font-weight: 500;

                text-decoration: none;

                &::after {
                    display: none;
                    opacity: 0 !important;
                }

                transition: all 0.3s ease;

                &:hover, &:active, &:focus {
                    background-color: $blue;
                    color: white;
                    border-color: $blue;
                }
            }
        }
    }

    #CookielawCross {
        background: url('../img/close.svg') no-repeat 0 0;
        cursor: pointer;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 15px;
        height: 15px;
    }
}
