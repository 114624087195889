/****************************************************************
/ GENERAL STYLING
/****************************************************************/

html {
    font-size: 10px; // rem base

    scroll-behavior: smooth; // smooth anchors (top)
}

body {
    margin: 0;

    font-family: $font;
    font-size: $f-30;
    line-height: 1;
    letter-spacing: -0.011em;
    background-color: $cream;

    // font rendering and aliasing
    text-rendering: geometricPrecision;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    position: relative; // to be able to place header

    cursor: default;

    // overscroll
    overscroll-behavior: none;
}

// ····························
// links

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

p a,
a:hover {
    text-decoration: underline;
}

p a:hover {
    text-decoration: none;
}

a.btn:hover,
a.no-deco {
    text-decoration: none;
}

// ····························
// headings

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-alt;
}

h1 {
    font-family: $font;
    font-weight: 100;
    font-size: $f-100;
    letter-spacing: -0.0333em;
    line-height: 1;
}

// ····························
// general spacing

.seccio,
.header,
.footer {
    padding-top: $pad-top;
    padding-right: $pad-right;
    padding-bottom: $pad-bottom;
    padding-left: $pad-left;

    @media #{$media-laptop} {
        padding-top: $pad-top-l;
        padding-right: $pad-right-l;
        padding-bottom: $pad-bottom-l;
        padding-left: $pad-left-l;
    }

    @media #{$media-tablet} {
        padding-top: $pad-yt;
        padding-right: $pad-xt;
        padding-bottom: $pad-yt;
        padding-left: $pad-xt;
    }

    @media #{$media-mobile} {
        // padding-top: $pad-m;
        // padding-bottom: $pad-m;
        padding-right: $pad-m;
        padding-left: $pad-m;
    }
}

.seccio {
    display: block;
    overflow: hidden;

    &--blue {
        background-color: $blue;
        color: $cream;
    }

    &--cream {
        background-color: $cream;
        color: $blue;
    }

    &--white {
        background-color: white;
        color: $blue;
    }

    &--orange {
        background-color: $orange;
        color: $cream;
    }
}

/****************************************************************
/ HELPERS
/****************************************************************/

.orange {
    color: $orange;
}
.grey {
    color: $grey;
}
.cream {
    color: $cream;
}
.blue {
    color: $blue;
}

.altfont {
    font-family: $font-alt;
}
.uppercase {
    text-transform: uppercase;
}

.titol {
    font-size: $f-40;
    font-weight: 500;
    letter-spacing: -0.02em;
    margin-bottom: 1em;

    @media #{$media-tablet} {
        margin-bottom: $pad-yt;
    }

    @media #{$media-mobile} {
        margin-bottom: 2.5rem;
    }
}

.textblock {
    &__title {
        font-family: $font;
        font-size: $f-30;
        letter-spacing: -0.029em;
        margin-bottom: 1em;
    }
    &__text {
        font-size: $f-40;
        letter-spacing: -0.011em;
        line-height: 1.2;
        margin-bottom: 1em;
    }
}

// .bg-cover{
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
// }

// .bg-contain{
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: contain;
// }

// .grayscale{
//     filter: grayscale(100%);
// }

/****************************************************************
/ custom scrollbar
/****************************************************************/

body {
    overflow: auto;
}

// /* width */
// ::-webkit-scrollbar {
//     width: 10px;
//     height: 10px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//     background: $lightgrey;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//     background: $blue;
//     border-radius: 10px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//     background: $orange;
// }

.mobile-only {
    display: none;
    @media #{$media-mobile} {
        display: block;
    }
}

.mobile-only-flex {
    display: none;
    @media #{$media-mobile} {
        display: flex;
    }
}

.mobile-only-grid {
    display: none;
    @media #{$media-mobile} {
        display: flex;
    }
}

.tablet-only {
    display: none;
    @media #{$media-tablet} {
        display: block;
    }
}

.tablet-only-flex {
    display: none;
    @media #{$media-tablet} {
        display: flex;
    }
}

.tablet-only-grid {
    display: none;
    @media #{$media-tablet} {
        display: grid;
    }
}

.from-mobile {
    display: block;
    @media #{$media-mobile} {
        display: none !important;
    }
}

.from-mobile-flex {
    display: flex;
    @media #{$media-mobile} {
        display: none !important;
    }
}

.from-mobile-grid {
    display: grid;
    @media #{$media-mobile} {
        display: none !important;
    }
}

.from-tablet {
    display: block;
    @media #{$media-tablet} {
        display: none !important;
    }
}

.from-tablet-flex {
    display: flex;
    @media #{$media-tablet} {
        display: none !important;
    }
}

.from-tablet-grid {
    display: grid;
    @media #{$media-tablet} {
        display: none !important;
    }
}

// plugins

// animate.css

/* This changes all the animations globally */
.animate__animated {
    -webkit-animation-duration: 1s !important;
    animation-duration: 1s !important;

    -webkit-animation-delay: 0.5s !important;
    animation-delay: 0.5s !important;

    -webkit-animation-timing-function: ease-out !important;
    animation-timing-function: ease-out !important;
}

// aos

// [data-aos] {
//     transition-timing-function: ease-in-out;
//     transition-duration: 3s;
// }

// lazy bg images

.lazy {
    background-image: none !important;
    background-color: inherit !important;
}
