.page-contacte {

    // s0 / cover

    .cover {
        &__content {
            width: 40%;
        }

        @media #{$media-tablet} {

            &:not(.keep-mobile) {
                height: unset !important;
                min-height: unset !important;
                padding-bottom: 0;
            }

            &__image:not(.keep-mobile)  {
                position: relative;
                width: 100%;
                height: 120vw;
            }

            &__content {
                // margin-top: $pad-m;
                margin-left: $pad-m;
                margin-right: $pad-m;
                // margin-bottom: $pad-m;

                padding: $pad-m 0;

                width: unset !important;
                max-width: unset !important;

                &__highlight {
                    max-width: unset !important;
                    width: unset !important;
                    padding-left: 0 !important;
                    margin: 1em 0 0.7em 0;
                }
            }
        }
    }

    .s0 {
        .form {
            padding-bottom: 3rem;

            .searchwrap,
            &__input {
                margin-bottom: 0.6em;
                display: grid;
                grid-template-columns: 9.5em 1fr;
                align-items: start;
                max-width: 74rem;

                font-size: 2rem;

                &::before {
                    display: none;
                }
            }

            .btn {
                min-width: 17rem;
            }

            input {
                width: 100%;
                // max-width: 25em;

                &.error {
                    border: 2px solid $orange;

                    &::after {
                        content: 'not valid';
                    }
                }
                &.untouched {
                    border: 1px solid $cream!important;
                }
            }

            input, textarea {
                padding: 1.75rem 2rem;
                border: 1px solid $cream;
                border-radius: 3rem;
                cursor: text;
                max-width: 55rem;

                &:hover, &:active, &:focus {
                    background: $cream;
                    color: $blue;
                }
            }

            // submit 
            input[type="submit"] {
                margin-top: 7rem;
                display: inline;
                cursor: pointer;

                padding: 1.75rem 0;
                height: unset;
                line-height: 1;
                border-radius: 3rem;
                font-size: 2.2rem;
            }
        }


        @media only screen and (max-width : 1400px) {
            .form {
                &__input {
                    grid-template-columns: 1fr;
                    max-width: 55rem;
                }
            }
        }
    }
    .s1 {
        &__wrap {
            display: grid;
            grid-template-columns: repeat(auto-fit, 17em);
            grid-gap: 7rem;

            // + + + + + + + + + + + +
            // more styling @base -> textblock
            // + + + + + + + + + + + +

            .textblock {
                &__title {
                    font-size: $f-40;
                }
                &__text {
                    font-size: $f-40;
                    letter-spacing: -0.033em;
                    line-height: calc(45 / 40);
                    margin-bottom: 2em;
                }
            }
        }

        @media #{$media-mobile} {
            &__wrap {
                .textblock {
                    max-width: calc(100vw - $pad-m);
                }
            }
        }
    }
}
