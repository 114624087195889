table.pricetable {
    margin-bottom: 2.5em;
    width: 100%;

    th,
    td {
        vertical-align: bottom;
        padding: 0.4em 0.8em 0.3em 0.8em;

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
        }
    }

    th {
        font-weight: $semibold;
        padding-bottom: 0.5em;
        padding-top: 0;
    }

    tr {
        td {
            border-bottom: 1px solid $cream;
        }

        &:last-of-type td {
            border-width: 0;
        }
    }

    .extracolumn {
        max-width: 19rem;
    }

    .lastprice {
        width: 6rem;
    }

    .orange {
        font-weight: $semibold;
    }
}
