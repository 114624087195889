.carousel {
    margin-left: 27.5%;
    overflow: hidden;

    &__swiper {
        width: 77vw;
    }

    &__nav {
        margin-left: 27.5%;

        user-select: none;

        &__right,
        &__left {
            height: 3rem;
            width: 4rem;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;

            cursor: pointer;
            display: inline-block;
        }
        &__right {
            background-image: url('../img/arrow-right-blue.svg');
            &.orange {
                background-image: url('../img/arrow-right-orange.svg');
            }
        }
        &__left {
            background-image: url('../img/arrow-left-blue.svg');
            margin-right: 1rem;

            &.orange {
                background-image: url('../img/arrow-left-orange.svg');
            }
        }
    }

    .card__slide {
        margin-top: 0;
    }

    // swiper customization

    .swiper-wrapper {
        height: unset;
    }

    @media #{$media-laptop} { 
        .card__slide {
            width: fit-content;
        }
    }
    @media #{$media-mobile} { 
        margin-left: 0;

        &__swiper {
            width: 100%;
        }

        &__nav {
            margin-left: $pad-m;
        }

        .card__slide {
            margin-left: $pad-m;
        }
    }
}

// unuseful arrows

// .swiper-button-disabled{
//     display:none;
// }

.swiper-button-disabled.arrow-animate-left,
.swiper-button-disabled.arrow-animate-right {
    cursor: default;
    opacity: 0.3;

    &:hover {
        transform: none;
    }
}
