.header {
    position: absolute;
    padding-top: 0;
    padding-bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-h;
    z-index: 21;

    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 2em;
    align-items: center;
    justify-items: end;

    font-size: $f-25-menu;

    @media #{$media-laptop} {
        font-size: $f-25-menu-smaller;
    }

    &__logo {
        width: unquote("min(15vw, 27.5rem)");
        min-width: 14rem;
        padding-top: 5%;
    }

    .btn {
        border-color: $cream;
        background-color: $cream;
        color: $blue;

        &:hover,
        &:active,
        &:focus {
            border-color: $orange;
            background-color: $orange;
            color: $cream;
        }

        // adjust menu to fit screen

        font-size: $f-25-menu;

        min-width: unset;
        padding: 0 1.4em;

        @media #{$media-laptop} {
            padding: 0 1.3em;
            font-size: $f-25-menu-smaller;
        }

        @media #{$media-laptop-sm} {
            padding: 0 1.15em;
        }
    }

    &__menu {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: $f-25;
        letter-spacing: -0.016em;
        color: $blue;

        background-color: $cream;
        border-radius: 2.4em;

        &__specialitem {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;

            // tooltip
            .tooltip {
                display: block;
                position: absolute;
                left: 50%;
                transform: translate(-50%, -100%);
                bottom: -4.7rem; // Y position
                width: 0;
                height: 0;
                border: 1.3rem solid transparent;
                border-bottom-color: $cream;

                // animation
                transition: opacity 0.5s ease;
            }
        }
    }

    &__lang {
        position: relative;

        .arrow {
            pointer-events: none;
            position: absolute;
            right: 0.7em;
            top: 50%;
            transform: translateY(-50%);
            width: 0.5em;
            height: 0.5em;
            z-index: 200;

            //normal config
            &.white {
                display: none;
            }
            &.blue {
                display: block;
            }
        }

        &:hover {
            .arrow {
                // changes on hover
                &.white {
                    display: block;
                }
                &.blue {
                    display: none;
                }
            }
        }

        .btn {
            padding: 0 2em 0 1.4em;
            min-width: unset;
            text-align: unset;
        }

        .dropdown {
            position: relative;

            .dropdown-content {
                pointer-events: none;
                transition: height 0.4s ease;
                height: 2.4em;
                overflow: hidden;
                a {
                    opacity: 0;
                    transition: opacity 1.2s ease;
                }

                position: absolute;
                left: -1px;
                width: calc(100% + 2px);
                top: -1px;
                z-index: 25;

                border-radius: 1.2em;
            }

            &:hover .dropdown-content {
                pointer-events: all;
                height: calc(2.4em * 2 + 2px);

                a {
                    opacity: 1;
                }
            }
        }
    }

    &__displaybox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(1.2em + 4.7rem - 2.6rem));
        overflow-y: auto;
        z-index: 120;

        max-height: calc(100vh - 0.7 * 14vw);
        width: calc(100vw - 14vw + 10px);

        padding: 3vw 3.5vw 2.5vw 3.5vw;
        padding: 5rem 6.3rem 4rem 6.3rem;
        border-radius: 2rem;

        background-color: $cream;
        color: $blue;

        // animation
        transition: opacity 0.5s ease;

        &__content {
            font-size: $f-15;
            letter-spacing: -0.0076em;
            line-height: 1.667;
            font-weight: 500;

            column-count: 6;
            // display: flex;
            // flex-direction: column;
            // flex-wrap: wrap;
            gap: 3rem;

            > * {
                transition: opacity 0.9s ease-in-out;
            }

            &__title {
                -webkit-column-break-inside: avoid;
                page-break-inside: avoid;
                break-inside: avoid-column;

                font-weight: 600;
                margin-top: 2em;

                &:first-of-type {
                    margin-top: 0;
                }
            }
            // &__paisos{
            //     &__item{

            //     }
            // }
        }

        // temporada

        &.small {
            width: max-content;
            margin-top: -0.5rem;
            padding: 4rem;
            text-align: center;

            .header__displaybox__content {
                column-count: unset;

                &__title {
                    margin-top: 1em;
                    font-size: $f-25-menu;
                    letter-spacing: -0.029em;
                    font-weight: 500;
                    line-height: 1;

                    &:first-of-type {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    @media #{$media-laptop-sm} {
        gap: 1.5em;
    }

    @media #{$media-tablet} {
        grid-template-columns: 1fr;
        justify-content: space-between;
        height: $header-h-t;

        &__hamburger {
            position: fixed;
            top: 2rem;
            right: $pad-xt;
            width: 3.8rem;
            height: 3.8rem;

            min-width: unset;
            padding: 1rem 0.85rem;

            background-color: $blue;
            // border: 1px solid $blue;
            border-radius: 50%;
            z-index: 100;

            @media #{$media-mobile} {
                top: $pad-m;
                right: $pad-m;
                margin-top: 0;
            }
        }

        &__logo {
            position: absolute;
            top: 2rem;
            left: $pad-xt;
            z-index: 9999;

            &.mobile-only {
                position: fixed;
                transition: opacity 0.6s ease-out;
                opacity: 0;
                &.expanded {
                    opacity: 1;
                }
            }
        }

        &__displaybox {
            display: none !important;

            &mobile {
                position: absolute;
                z-index: 200;
                top: 100%;
                left: 0;
                width: 100%;
                max-height: 60vh;
                overflow-y: auto;

                &.hidden,
                .hidden {
                    display: none;
                }

                background-color: white;
                // font-size: $f-26;
                // line-height: 1.25;
                // letter-spacing: -0.011em;

                font-size: $f-30;
                letter-spacing: -0.029em;
                line-height: calc(35 / 30);

                padding: 0.5em 1em;
                border-radius: 1em;

                ul {
                    color: $blue;
                }

                &__item {
                    margin-top: 0.5em;
                    margin-bottom: 0.5em;

                    span {
                        margin-bottom: 0.5em;
                        display: block;
                    }
                    div {
                        margin-bottom: 0.5em;
                    }
                    .temporada {
                        color: $blue;
                    }
                }
            }
        }

        &__menumobile {
            position: fixed;
            z-index: 99;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            overflow: scroll;

            flex-direction: column;
            align-content: center;
            justify-content: center;
            text-align: center;
            padding: calc(#{$header-h-t} + 5rem) 3rem 3rem 3rem;

            transition: opacity 0.6s ease-out, transform 0.6s ease-out;
            transform: translateY(-120%);
            opacity: 0;
            &.expanded {
                opacity: 1;
                transform: translateY(0);
            }

            font-size: $f-35;
            line-height: 2.1em;
            letter-spacing: -0.029em;
            background-color: $cream;
            color: $orange;

            &__launcher {
                position: relative;
            }

            a {
                display: block;
            }

            .header__lang {
                width: fit-content;
                color: $blue;
                margin: 5rem auto;

                .dropdown {
                    .dropdown-content {
                        left: 0;
                        width: fit-content;
                        top: 0;
                        z-index: 100;
                        border-radius: unset;
                        background-color: $cream;

                        a {
                            line-height: 1em;
                        }

                        // just show
                        height: fit-content;
                        opacity: 1;
                        pointer-events: all;
                    }
                }

                span::after {
                    content: url("../img/form-arrow-blue.svg");
                    display: inline-block;
                    transform: scale(0.5);
                    position: relative;
                    top: 0.1em;
                    margin-right: -0.3em;
                }

                .arrow {
                    // right: -1.4em;

                    // &.blue {
                    //     display: block !important;
                    // }
                    // &.white {
                    //     display: none !important;
                    // }
                    display: none !important;
                }
            }
        }
    }
}

// adapting LOGO to different Pages
// (default: logo-dark.svg)

#logo-light { display: none; }
#logo-orange { display: none; }

.page-landing,
.page-results {
    #logo-orange { display: block; }
    #logo-dark { display: none; }
}

.page-viatges,
.page-contacte,
.single-pais,
.single-noticia.dark {
    #logo-light { display: block; }
    #logo-dark { display: none; }
}

// adapting buttons + country display

.page-landing:not(.alt-select-colors) {
    .header {
        .btn {
            &:hover,
            &:active,
            &:focus {
                border-color: $blue;
                background-color: $blue;
                color: $cream;
            }
        }
    }
}

.page-news,
.page-about,
.single-viatge,
.single-noticia.light {
    .header {
        &__menu {
            background-color: $blue;

            .tooltip {
                border-bottom-color: $blue;
            }
        }
        .btn {
            @extend .btn--blue;
        }

        &__lang {
            .arrow {
                //alt config
                &.white {
                    display: block;
                }
                &.blue {
                    display: none;
                }
            }

            &:hover {
                .arrow {
                    //alt config (same on hover)
                    &.white {
                        display: block;
                    }
                    &.blue {
                        display: none;
                    }
                }
            }
        }

        &__displaybox {
            background-color: $blue;
            color: $cream;

            &__content {
                &__continent__title {
                    color: $orange;
                }
            }
        }
    }
}

.page-results:not(.alt-select-colors) {
    .header {
        &__menu {
            background-color: $blue;

            .tooltip {
                border-bottom-color: $blue;
            }
        }
        .btn {
            border-color: $blue;
            background-color: $blue;
            color: $cream;

            &:hover,
            &:active,
            &:focus {
                border-color: $cream !important;
                background-color: $cream !important;
                color: $blue !important;
            }
        }

        &__lang {
            .arrow {
                //alt config
                &.white {
                    display: block;
                }
                &.blue {
                    display: none;
                }
            }

            &:hover {
                .arrow {
                    //alt config (same on hover)
                    &.white {
                        display: block;
                    }
                    &.blue {
                        display: none;
                    }
                }
            }
        }

        &__displaybox {
            background-color: $blue;
            color: $cream;

            &__content {
                &__continent__title {
                    color: $orange;
                }
            }
        }
    }
}
