.card {
    &__slide {
        margin-top: 6rem; // space between cards (compensate negative bottom)
        height: 31vw;
        width: 25vw;
    }

    &__bgwrap {
        z-index: 0;
        position: relative;
        border-radius: 2rem;
        overflow: hidden;

        height: 31vw;
        width: 25vw;

        // border: 1px solid $grey; // there's a bug if I set it

        &__img {
            height: inherit;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &__content {
        color: $cream;
        padding: 2rem;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;

        &__viatge-detalls {
            letter-spacing: -0.029em;
            margin-bottom: 0.5em;
        }

        &__viatge-titol {
            font-size: $f-70;
            letter-spacing: -0.0111em;
            line-height: 1;
        }

        &--equip {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: flex-end;
            inset: 0;
        }

        &__equip-nom {
            font-family: $font;
            font-size: $f-85;
            line-height: 1;
            max-width: 80%;
        }
        &__equip-email {
            font-family: $font;
            font-size: $f-22;
            line-height: 1;
            overflow-wrap: break-word;
        }
    }

    &__price {
        transform: translateY(-50%);
        z-index: 2;
        position: relative;
        margin-left: 2rem;
        border-width: 0;
    }

    // responsive
    @media #{$media-laptop} { 
        &__slide {
            height: 40vw;
            width: 30vw;
        }
    
        &__bgwrap {
            height: 40vw;
            width: 30vw;
        }
    }

    @media #{$media-tablet} { 
        &__slide {
            height: 50vw;
            width: 38vw;
        }
    
        &__bgwrap {
            height: 50vw;
            width: 38vw;
        }
    }
    
    @media #{$media-mobile} { 

        &__slide {
            margin-top: 4rem; // space between cards (compensate negative bottom)
            height: 31rem;
            width: 25rem;
        }
    
        &__bgwrap {
            border-radius: $pad-m;
            height: 31rem;
            width: 25rem;
        }

        &__content {
            padding: $pad-m;
        }

        &__price {
            margin-left: $pad-m;
        }
    }

    // UI

    &__bgwrap__img,
    &__price {
        transition: all 0.5s ease;
    }

    &__slide:hover {
        .card__bgwrap__img {
            transform: scale(1.1);
            transform-origin: center;
        }

        .card__price {
            background-color: $blue;
            border-color: $blue;
        }
    }
}
