.page-about {
    // s1 - cover

    .s1 {
        &__text {
            max-width: 20em;

            font-size: $f-70;
            line-height: calc(75 / 70);
            letter-spacing: -0.033em;
            font-family: $font-alt;
        }

        &__wrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 9rem;
            margin-left: 20.5vw;
            grid-gap: 7rem;

            // + + + + + + + + + + + +
            // more styling @base -> textblock
            // + + + + + + + + + + + +

            .textblock {
                &__text {
                    font-size: $f-32;
                    letter-spacing: -0.01em;
                    line-height: calc(40 / 32);
                    margin-bottom: 2em;
                }
            }
        }

        @media #{$media-mobile} {
            &__wrap {
                grid-template-columns: 1fr;
                margin-left: 0;
                grid-gap: 1.5em;
            }
        }
    }

    // dades

    .dades {
        padding-top: 2em;
        padding-bottom: 1.5em;

        &__content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2em;
        }
        &__dada {
            text-align: center;
            h3 {
                font-size: $f-60;
                letter-spacing: -0.011em;
                margin-bottom: 0.6em;
            }
            img {
                height: 2em;
                width: auto;
            }
        }

        @media #{$media-tablet} {
            &__content {
                flex-wrap: wrap;
                justify-content: center;
                max-width: 72%;
                margin: 0 auto;
            }
        }

        @media #{$media-mobile-sm} {
            &__content {
                max-width: unset;
            }
        }
    }

    // foto amb text a sobre

    .s2 {
        background-image: linear-gradient(to bottom, $cream 2rem, #fff 2rem);

        &__content {
            max-width: 72%;
            margin: auto;
            position: relative;
        }
        &__img {
            min-height: 47vw;
            width: 100%;
            border-radius: 2rem;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            display: flex;
            flex-direction: column;
            align-items: center;

            text-align: center;
            padding: 7rem 10vw;

            h4 {
                font-family: $font;
                font-size: $f-40;
                letter-spacing: -0.02em;
                margin-bottom: 2rem;
                font-weight: $regular;
            }
            h3 {
                font-family: $font;
                font-size: $f-65;
                line-height: 1.154;
                letter-spacing: -0.033em;
                max-width: 13em;
                margin-bottom: 20rem;
            }
        }
        &__texts {
            padding: 5rem 0 9rem 0;
            display: flex;
            gap: 2.5vw;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        &__text {
            max-width: 14em;
            p {
                font-size: $f-30;
                letter-spacing: -0.029em;
                margin-bottom: 2rem;
            }
            h5 {
                font-family: $font;
                font-size: $f-40;
                line-height: calc(50 / 40);
                letter-spacing: -0.011em;
            }
        }

        @media only screen and (max-width: 1300px) {
            &__texts {
                justify-content: flex-start;
                gap: 2em 3em;
                margin-top: 0.5em;
            }
        }

        @media only screen and (max-width: 890px) {
            &__texts {
                display: grid;
                justify-content: center;
            }
            &__text {
                max-width: unset;
            }
        }

        @media #{$media-mobile} {
            &__content {
                max-width: 82%;
            }

            &__img {
                border-radius: $pad-m;

                h4 {
                    margin-bottom: 2em;
                }
            }
        }

        @media #{$media-mobile-sm} {
            &__content {
                max-width: unset;

                margin-left: $pad-m;
                margin-right: $pad-m;
            }

            &__img {
                h3 {
                    margin-bottom: 21rem;
                }
            }
        }
    }
    .s3 {
        &__destacat {
            font-family: $font;
            font-size: $f-70;
            font-weight: 100;
            letter-spacing: -0.0333em;
            line-height: 1;

            margin-bottom: 2.2em;
            margin-top: 1.5em;
            max-width: 20em;
        }
        &__text {
            display: grid;
            grid-template-columns: 1fr 1fr;
            font-family: $font;
            p {
                font-size: $f-35;
                letter-spacing: -0.011em;
                grid-column-start: 2;
                line-height: 1.14;
            }
        }

        @media #{$media-mobile} {
            &__text {
                grid-template-columns: 0.25fr 1fr;
            }
        }

        @media only screen and (max-width: 600px) {
            &__text {
                grid-template-columns: 1fr;
            }
        }
    }

    .s3bis {
        background-image: linear-gradient(to bottom, $orange 3.2em, #fff 3.2em);
        padding-top: 0.3em;

        &__wrap {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 2.5rem;
            grid-auto-rows: min-content;

            // + + + + + + + + + + + +
            // more styling @base -> textblock
            // + + + + + + + + + + + +
            .textblock {
                color: #fff;
                background-color: $blue;
                border-radius: 2.5rem;
                padding: 2em 1.5em 1.7em 1.5em;
                &__text {
                    margin-bottom: 0;
                }
            }
        }

        @media #{$media-laptop-sm} {
            &__wrap {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        @media #{$media-mobile} {
            &__wrap {
                grid-template-columns: 1fr;
            }
        }
    }

    // equip

    .s4 {
        padding-top: 3rem;
        padding-bottom: $pad-bottom;

        .titol {
            padding-right: $pad-right;
            padding-left: $pad-left;
        }
        .card__slide {
            margin-bottom: 0;
        }
        // + + + + + + + + + + + +
        // more styling @carousel
        // + + + + + + + + + + + +

        .carousel__nav {
            margin-top: 2.5rem;
        }

        @media #{$media-laptop} {
            padding-bottom: $pad-bottom-l;

            .titol {
                padding-right: $pad-right-l;
                padding-left: $pad-left-l;
            }
        }

        @media #{$media-tablet} {
            padding-bottom: $pad-yt;

            .titol {
                padding-right: $pad-xt;
                padding-left: $pad-xt;
            }
        }

        @media #{$media-mobile} {
            .titol {
                padding-right: $pad-m;
                padding-left: $pad-m;
                margin-bottom: 2rem;
            }
        }
    }
}
