/******************************************************************/
/*** The new CSS Reset - version 1.2.0 (last updated 23.7.2021) ***/

/* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
// *:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
*:where(:not(iframe, canvas, img, svg, video):not(svg *):not(ol, ol li)) {
        all: unset;
    display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/*
Remove list styles (bullets/numbers)
in case you use it with normalize.css
*/
ol, ul {
    list-style: none;
}

/* For images to not be able to exceed their container */
img,svg {
    max-width: 100%;
}

/* Removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* Revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}


/****************************************************************
/ https://css-tricks.com/css-fix-for-100vh-in-mobile-webkit/
/****************************************************************/

body {
    min-height: 100%;
    min-height: -webkit-fill-available;
}

html {
    height: -webkit-fill-available;
}

ul, ol {
    padding: 0;
    margin: 0;
}

i, em {
    font-style: italic;
}