.newslist {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    margin-right: -6vw;
    margin-bottom: -6vw;

    &__item {
        display: block;
        width: 25vw;
        margin-right: $pad-xt;
        margin-bottom: $pad-bottom;

        &__image {
            width: 100%;
            height: 32vw;
            min-height: 25rem;
            margin-bottom: 1em;

            position: relative;

            &__inner {
                position: absolute;
                left: 0;
                bottom: 0;
                height: auto;
                width: 100%;
                overflow: hidden;

                border-radius: 2.5rem;

                img {
                    border-radius: inherit;

                    object-position: center;
                    object-fit: cover;
                    height: auto;
                    max-height: 32vw;
                    min-height: 25rem;
                    width: 100%;
                }
            }
        }

        &__data {
            letter-spacing: -0.029em;
            margin-bottom: 0.3em;
        }
        &__title {
            font-size: $f-75;
            letter-spacing: -0.011em;
            line-height: calc(85 / 75);
            margin-bottom: 0.6em;
        }
        &__summary {
            margin-bottom: 0.6em;
            letter-spacing: -0.029em;
        }
        &__arrow {
            width: 1.75em;

            // animation
            transition: all 0.5s ease;
        }
    }

    // UI

    &__item {

        &__image__inner img {
            transition: all 0.5s ease;
            transform-origin: center;
        }

        &:hover {
            .newslist__item {
                &__arrow {
                    transform: translateX(40%);
                }
    
                &__image__inner img {
                    transform: scale(1.1);
                }
            }
        }
    }

    // responsive

    @media #{$media-laptop} {
        margin-right: -5vw;
        margin-bottom: -5vw;

        &__item {
            width: 35vw;
            margin-right: 5vw;
            margin-bottom: 5vw;
        }
    }

    @media #{$media-tablet} {
        margin-right: -$pad-xt;
        margin-bottom: -$pad-yt;

        &__item {
            width: 45vw;
            max-width: 33rem;
            margin-right: $pad-xt;
            margin-bottom: $pad-yt;

            &__image {
                border-radius: $pad-m;
            }
        }
    }

    @media #{$media-mobile} {
        margin-right: -$pad-m;
        margin-bottom: -$pad-yt;

        &__item {
            width: calc(100vw - 2 *#{$pad-m});
            max-width: 42rem;
            margin-right: $pad-m;
            margin-bottom: $pad-yt;

            &__image {
                min-height: unset;
                height: unset;

                &__inner {
                    position: relative;

                    img {
                        min-height: unset;
                        max-height: unset;
                    }
                }
            }
        }
    }
}
