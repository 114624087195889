// Ariata Stencil

// @font-face {
//     font-family: "AriataStencil";
//     src: url("../fonts/AriataStencil-Regular.woff2") format("woff2"),
//         url("../fonts/AriataStencil-Regular.ttf") format("truetype");
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// Ariata Display

@font-face {
    font-family: 'Ariata Display';
    src: url('../fonts/AriataDisplay-Regular.woff2') format('woff2'),
        url('../fonts/AriataDisplay-Regular.woff') format('woff'),
        url('../fonts/AriataDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



// ArminGrotesk - normal (normal / 400)

@font-face {
    font-family: "Armin Grotesk";
    src: url("../fonts/ArminGrotesk-Normal.woff2") format("woff2"),
        url("../fonts/ArminGrotesk-Normal.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Armin Grotesk";
    src: url("../fonts/ArminGrotesk-NormalItalic.woff2") format("woff2"),
        url("../fonts/ArminGrotesk-NormalItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

// ArminGrotesk - regular (500)

@font-face {
    font-family: "Armin Grotesk";
    src: url("../fonts/ArminGrotesk-Regular.woff2") format("woff2"),
        url("../fonts/ArminGrotesk-Regular.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Armin Grotesk";
    src: url("../fonts/ArminGrotesk-Italic.woff2") format("woff2"),
        url("../fonts/ArminGrotesk-Italic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

// ArminGrotesk - semi-bold (600)

@font-face {
    font-family: "Armin Grotesk";
    src: url("../fonts/ArminGrotesk-SemiBold.woff2") format("woff2"),
        url("../fonts/ArminGrotesk-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Armin Grotesk";
    src: url("../fonts/ArminGrotesk-SemiBoldItalic.woff2") format("woff2"),
        url("../fonts/ArminGrotesk-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

// ArminGrotesk - ultralight (200)

@font-face {
    font-family: "Armin Grotesk";
    src: url("../fonts/ArminGrotesk-UltraLight.woff2") format("woff2"),
        url("../fonts/ArminGrotesk-UltraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Armin Grotesk";
    src: url("../fonts/ArminGrotesk-UltraLightItalic.woff2") format("woff2"),
        url("../fonts/ArminGrotesk-UltraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

// ArminGrotesk - thin (100)

@font-face {
    font-family: "Armin Grotesk";
    src: url("../fonts/ArminGrotesk-Thin.woff2") format("woff2"),
        url("../fonts/ArminGrotesk-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Armin Grotesk";
    src: url("../fonts/ArminGrotesk-ThinItalic.woff2") format("woff2"),
        url("../fonts/ArminGrotesk-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
