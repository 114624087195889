.single-pais {
    .s0 {
        // + + + + + + + + + + + +
        // more styling @cover
        // + + + + + + + + + + + +
    }

    .s1 {
        &__text {
            font-size: $f-40;
            letter-spacing: -0.0332em;
            line-height: calc(45 / 40);
            width: 70%;
        }

        @media #{$media-mobile} {
            &__text {
                width: unset;
            }
        }
    }

    .s2 {
        .titol {
            display: flex;
            align-items: center;
        }
    }

    // + + + + + + + + + + + +
    // more styling @card
    // + + + + + + + + + + + +

    .card {
        // &__slide {
        //     width: 100%;
        //     height: 33vw;
        //     margin-top: 5rem;
        // }

        // &__bgwrap {
        //     width: 100%;
        //     height: 33vw;
        // }
    }
}
