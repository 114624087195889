.single-noticia{

    .seccio--narrow {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: calc(2.3 * #{$pad-right});
        padding-left: calc(2 * #{$pad-left});

        @media #{$media-laptop} {
            padding-right: calc(2.3 * #{$pad-right-l});
            padding-left: calc(2 * #{$pad-left-l});
        }

        @media #{$media-tablet} {
            padding-right: calc(2.3 * #{$pad-xt});
            padding-left: calc(2 * #{$pad-xt});
        }

        @media #{$media-mobile} {
            padding-right: $pad-m;
            padding-left: $pad-m;
        }
    }


    // cover

    .s0 {

        min-height: 80vh;

        .cover {           
            &__image {
                width: 100%;
                min-height: 80vh;
            }
        }
    }


    &.dark {
        .s0 {
            .cover {           
                &__content {
                    color: white;
                }
            }
        }
    }

    // body

    .s1 {
        &__heading {
            h2 {
                font-size: $f-70;
                line-height: calc(75 / 70);
                letter-spacing: -0.033em;
            }

            padding-right: calc(2.3 * #{$pad-right});
        
            @media #{$media-laptop} {
                padding-right: calc(2.3 * #{$pad-right-l});
            }
        
            @media #{$media-tablet} {
                padding-right: calc(2.3 * #{$pad-xt});
            }
        
            @media #{$media-mobile} {
                padding-right: $pad-m;
            }
        }

        &__wrap {
            display: flex;
            flex-direction: column;
            gap: $pad-bottom;
            margin-bottom: $pad-bottom;

            article:last-of-type {
                .big-image {
                    margin-bottom: -$pad-bottom; // remove margin if last element is image
                }
            }

            @media #{$media-mobile} {
                gap: $pad-yt;
                margin-bottom: $pad-xt;

                article:last-of-type {
                    .big-image {
                        margin-bottom: -$pad-xt;
                    }
                }
            }
        }

        &__block {

            &--image {
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 70vh;
                    min-height: 50rem;
                }
            }

            &--gallery {
                position: relative;
                height: fit-content;

                .swiper-slide {
                    width: fit-content;

                    img {
                        width: auto;
                        max-width: unset;
                        height: 60vh;
                        border-radius: 2.5rem;
                    }

                    &:first-of-type {
                        img {
                            margin-left: $pad-right;
    
                            @media #{$media-laptop} {
                                margin-left: $pad-right-l;
                            }
                        
                            @media #{$media-tablet} {
                                margin-left: $pad-xt;
                            }
                        
                            @media #{$media-mobile} {
                                margin-left: $pad-m;
                            }
                        }
                    }

                    &:last-of-type {
                        img {
                            margin-right: calc(2.3 * #{$pad-right});
                    
                            @media #{$media-laptop} {
                                margin-right: calc(2.3 * #{$pad-right-l});
                            }
                        
                            @media #{$media-tablet} {
                                margin-right: calc(2.3 * #{$pad-xt});
                            }
                        
                            @media #{$media-mobile} {
                                margin-right: $pad-m;
                            }
                        }

                    }
                }

                .swiper-navigation {
                    padding-top: 2.5rem;
                    padding-bottom: 2.5rem;
                }
            }

            &--highlight {
                color: $midgrey;
                font-size: $f-40;
                letter-spacing: -0.033em;
                line-height: calc(45 / 40);

                p {
                    margin-bottom: 0.5em;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                // margin-bottom: 1em;
            }

            &--cols {
                column-count: 2;
                column-gap: 6vw;

                font-size: $f-32;
                line-height: 1.25;
                letter-spacing: -0.011em;

                p {
                    margin-bottom: 0.5em;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                @media #{$media-mobile} {
                    column-count: 1;
                }

                // margin-bottom: 1.5em;
            }

            &--quote {
                margin: 6rem 25%;
                
                h3 {
                    text-align: center;
                    font-size: $f-60;
                    letter-spacing: -0.011em;
                }

                @media #{$media-tablet} {
                    margin: 4rem 15%;
                }

                @media #{$media-mobile} {
                    margin: 4rem 10%;
                }
            }
        }
    }
}