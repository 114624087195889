// selector of results

.result-select {
    display: flex;
    align-items: center;
    margin-left: 0.7em;
    margin-bottom: 0.1em;

    .btn {
        display: flex;
        align-items: center;
        margin-left: 1em;
        text-transform: capitalize;

        min-width: unset;
        font-size: $f-25;
        padding: 0 1em;
        justify-content: center;
        margin: 0 0.5em 0 0;

        span {
            margin-left: 1em;
        }

        // hovers
        transition: none;
        &:hover,
        &:active,
        &:focus {
            background-color: $orange;
            border-color: $orange;
        }
    }
}

// no results display

.no-results {
    margin-top: 12rem;
    max-width: 65%;

    .btn {
        margin-top: 3rem;
    }

    @media #{$media-mobile} {
        margin-top: 7rem;
        max-width: unset;
    }
}
