.footer {
    background-color: $lightgrey;
    color: $blue;

    padding-bottom: 4vh;

    // ························
    // floating arrow (to top)

    &__floatingarrow {
        position: fixed;
        z-index: 20;

        bottom: 3rem;
        right: 4rem;
        height: 4rem;
        width: 5rem;

        background-image: url("../img/arrow-up-blue.svg");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: contain;

        // animation
        transition: all 0.5s ease;
        transition: transform 0.5s ease, background-image 0.2s;

        // viatges single mobile
        &.hide {
            display: none;
        }
    }

    // animation
    &__floatingarrow:hover {
        transform: translateY(-40%);
    }
    &__floatingarrow.orange {
        background-image: url("../img/arrow-up-orange.svg");
    }

    // ························
    // main wrap

    &__wrap {
        display: grid;
        grid-template-columns: 45% 1fr;
        margin-bottom: 15rem;

        &__newsletter {
            &__frase {
                font-size: $f-65;
                line-height: calc(75 / 65);
                letter-spacing: -0.033em;
                margin-bottom: 5rem;
                max-width: 8.6em;
            }

            &__form {
                border-bottom: 1px solid $blue;
                padding-bottom: 0.8rem;
                max-width: 53rem;

                display: flex;
                justify-content: space-between;

                &__input {
                    font-size: $f-30;
                    letter-spacing: -0.029em;
                    line-height: calc(35 / 30);
                }

                &__submit {
                    cursor: pointer;
                    background-image: url("../img/arrow-right-blue.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 4rem;

                    // animation
                    transition: transform 0.5s ease;
                }

                // animation
                &__submit:hover {
                    transform: translateX(40%);
                }
            }

            .form-positive {
                max-width: 53rem;
            }
        }

        &__links {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            margin-top: 1.2rem;

            .textblock {
                width: 20vw;

                &__title {
                }

                &__text {
                    font-size: $f-30;
                    letter-spacing: -0.011em;
                    line-height: calc(35 / 30);
                    margin-bottom: 2em;
                }
            }

            // + + + + + + + + + + + +
            // more styling @base -> .textblock
            // + + + + + + + + + + + +

            &__item {
                display: block;
            }
        }
    }

    // ························
    // signature + legal

    &__foot {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        font-size: $f-22;
        letter-spacing: -0.029em;

        &__legal {
            &__item::after {
                content: "-";
                margin: 0 0.3em;
            }

            &__item:last-of-type:after {
                content: "";
            }
        }
    }

    @media #{$media-laptop} {
        &__wrap {
            grid-template-columns: 1fr;
            gap: 2em;

            &__newsletter {
                &__form, 
                .form-positive {
                    max-width: calc(100vw - 2 *#{$pad-m});
                    max-width: 42rem;
                }
            }

            &__links {
                justify-content: space-between;

                .textblock {
                    width: unset;
                }
            }
        }
    }

    @media #{$media-tablet} {
        &__floatingarrow {
            right: $pad-xt;
        }
    }

    @media #{$media-mobile} {
        &__floatingarrow {
            bottom: $pad-m;
            right: $pad-m;
        }

        &__wrap {
            &__links {
                display: block;
            }
        }

        &__foot {
            display: block;

            &__legal {
                margin-top: 0.5em;
                margin-bottom: 5rem;
            }
        }
    }

    @media only screen and (max-width : 500px) {
        &__wrap {
            &__newsletter {
                &__form, 
                .form-positive {
                    max-width: 100%;
                }
            }
        }
    }
}
